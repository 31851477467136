import React, {FC, useEffect, useState} from "react";
import {Button, Form, message, Slider, Space} from "antd";
import {SliderMarks} from "antd/es/slider";
import {Group, UpdateGroupPermissionCommand} from "../../../app/models/group";
import useUpdateGroupPermission from "../../../app/hooks/group/useUpdateGroupPermission";
import ErrorUtils from "../../../app/utils/error.utils";
import {hasPermission} from "../../../app/utils/auth.utils";

const valueMap = (type: string) => {
    return {
        [`read:${type}`]: 50,
        [`write:${type}`]: 100
    }
}

const getValue = (permissions: string[], type: string) => {
    let maxValue = 0;
    for (const p of permissions) {
        if (p.endsWith(":" + type)) {
            const value = valueMap(type)[p] || 0;
            if (value > maxValue) {
                maxValue = value
            }
        }
    }
    return maxValue;
}

const getPermissions = (values: any, type: string) => {
    const permissions: string[] = [];

    const userPermissionValue = values[type];
    if (userPermissionValue === 100) {
        permissions.push('read:' + type);
        permissions.push('write:' + type);
    } else if(userPermissionValue === 50) {
        permissions.push('read:' + type);
    }
    return permissions;
}

type Props = {
    group: Group
}

const PermissionAssigment: FC<Props> = ({group}) => {

    const [form] = Form.useForm();
    const [currentPermissions, setCurrentPermissions] = useState<string[]>([]);

    const {mutate: updatePermission} = useUpdateGroupPermission();

    useEffect(() => {
        if (!group.permissions) {
            form.setFieldsValue({});
            return;
        }
        const permissions = group.permissions.map(item => item.code);
        setCurrentPermissions(permissions);
        loadPermission(permissions);
    }, [group]);

    const loadPermission = (permissions: string[]) => {
        const values = {
            user: getValue(permissions, 'user'),
            group: getValue(permissions, 'group'),
            role: getValue(permissions, 'role'),
            workflow: getValue(permissions, 'workflow'),
            form: getValue(permissions, 'form'),
            file: getValue(permissions, 'file'),
            single_task_config: getValue(permissions, 'single_task_config'),
            setting: getValue(permissions, 'setting'),
            drive: getValue(permissions, 'drive'),
        };
        console.log(values)
        form.setFieldsValue({...values});
    }

    const permissions: SliderMarks = {
        0: 'Không có quyền',
        50: 'Chỉ xem',
        100: 'Quản trị'
    };

    const onFinish = (values: any) => {
        let permissions: string[] = [];

        for (const key of Object.keys(values)) {
            const result = getPermissions(values, key);
            permissions = permissions.concat(result);
        }

        const request: UpdateGroupPermissionCommand = {
            id: group.id,
            permissions: permissions
        }
        updatePermission(request, {
            onSuccess: () => {
                message.success("Cập nhật thành công");
                setCurrentPermissions(request.permissions);
            },
            onError: ErrorUtils.showCommonError
        })
    }

    const onCancel = () => {
        loadPermission(currentPermissions);
    }

    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinish}
        >
            <div className="permission-assignment__wrap">
                <div className="permission-assignment__header">
                    <div className="permission-assignment__header__title ">
                        Chức năng
                    </div>
                    <div className="permission-assignment__header__description">
                        <span>Không có quyền</span>
                        <span>Chỉ xem</span>
                        <span>Quản trị</span>
                    </div>
                </div>
                <div className="permission-assignment__row">
                    <div className="permission-assignment__row__title">
                        Người dùng
                    </div>
                    <div className="permission-assignment__row__value">
                        <Form.Item name="user" noStyle>
                            <Slider marks={permissions} step={null} defaultValue={0} tooltipVisible={false} disabled={!hasPermission('write:group')}/>
                        </Form.Item>
                    </div>
                </div>
                <div className="permission-assignment__row">
                    <div className="permission-assignment__row__title">
                        Nhóm người dùng
                    </div>
                    <div className="permission-assignment__row__value">
                        <Form.Item name="group" noStyle>
                            <Slider marks={permissions} step={null} defaultValue={0} tooltipVisible={false} disabled={!hasPermission('write:group')}/>
                        </Form.Item>
                    </div>
                </div>
                <div className="permission-assignment__row">
                    <div className="permission-assignment__row__title">
                        Chức vụ
                    </div>
                    <div className="permission-assignment__row__value">
                        <Form.Item name="role" noStyle>
                            <Slider marks={permissions} step={null} defaultValue={0} tooltipVisible={false} disabled={!hasPermission('write:group')}/>
                        </Form.Item>
                    </div>
                </div>
                <div className="permission-assignment__row">
                    <div className="permission-assignment__row__title">
                        EzDrive
                    </div>
                    <div className="permission-assignment__row__value">
                        <Form.Item name="drive" noStyle>
                            <Slider marks={permissions} step={null} defaultValue={0} tooltipVisible={false} disabled={!hasPermission('write:group')}/>
                        </Form.Item>
                    </div>
                </div>
                <div className="permission-assignment__row">
                    <div className="permission-assignment__row__title">
                        Quy trình
                    </div>
                    <div className="permission-assignment__row__value">
                        <Form.Item name="workflow" noStyle>
                            <Slider marks={permissions} step={null} defaultValue={0} tooltipVisible={false} disabled={!hasPermission('write:group')}/>
                        </Form.Item>
                    </div>
                </div>
                <div className="permission-assignment__row">
                    <div className="permission-assignment__row__title">
                        Biểu mẫu
                    </div>
                    <div className="permission-assignment__row__value">
                        <Form.Item name="form" noStyle>
                            <Slider marks={permissions} step={null} defaultValue={0} tooltipVisible={false} disabled={!hasPermission('write:group')}/>
                        </Form.Item>
                    </div>
                </div>
                <div className="permission-assignment__row">
                    <div className="permission-assignment__row__title">
                        Files
                    </div>
                    <div className="permission-assignment__row__value">
                        <Form.Item name="file" noStyle>
                            <Slider marks={permissions} step={null} defaultValue={0} tooltipVisible={false} disabled={!hasPermission('write:group')}/>
                        </Form.Item>
                    </div>
                </div>
                <div className="permission-assignment__row">
                    <div className="permission-assignment__row__title">
                        Mẫu nhiễm vụ
                    </div>
                    <div className="permission-assignment__row__value">
                        <Form.Item name="single_task_config" noStyle>
                            <Slider marks={permissions} step={null} defaultValue={0} tooltipVisible={false} disabled={!hasPermission('write:group')}/>
                        </Form.Item>
                    </div>
                </div>
                <div className="permission-assignment__row">
                    <div className="permission-assignment__row__title">
                        Cài đặt hệ thống
                    </div>
                    <div className="permission-assignment__row__value">
                        <Form.Item name="setting" noStyle>
                            <Slider marks={permissions} step={null} defaultValue={0} tooltipVisible={false} disabled={!hasPermission('write:group')}/>
                        </Form.Item>
                    </div>
                </div>
            </div>

            {
                hasPermission('write:group') &&
                <div className="flex justify-content-space-between align-items-center">
                    <span></span>
                    <Space className="mt-2" align={"end"}>
                        <Button type="default" shape="round" onClick={onCancel}>Cancel</Button>
                        <Button type="primary" shape="round" htmlType="submit">Lưu lại</Button>
                    </Space>
                </div>
            }
        </Form>
    )
}

export default PermissionAssigment;