import FormioUtils from '_libs/formio/formioutils';
import { getToken } from 'app/services/TokenStorageService';
import axios from 'axios';
import { Components } from 'formiojs-llq';
import _ from 'lodash';
const selectboxesEditForm = (Components.components.selectboxes as any).editForm;
export default function (...extend: any) {
  const url = '/database-tables?size=10&page=0'
  return selectboxesEditForm([
    {
      key: 'data',
      components: [
        {
          key: 'dataSrc',
          label: 'Data Source Type',
          data: {
            values: [
              { label: 'Values', value: 'values' },
              { label: 'URL', value: 'url' },
              { label: 'Table in database', value: 'tables' },
            ],
          },
          onChange(context: any) {
            console.log("Data source change");
            if (context.data.dataSrc === 'tables') {
              context.data.authenticate = true;
              const getComponent = context.utils.getComponent;
              const listTableComponent = getComponent(context.form.components, 'listTable', true);
              let value: {}[] = [];
              axios.get<any>(url).then(res => {
                res.data.content.forEach((item: any) => {
                  value.push({ label: item.tableName, value: item.id })
                })
                _.set(listTableComponent, 'data.values', value);
              })
            } else {
              context.data.data.headers = [];
              context.data.authenticate = false;
            }
          },
          overrideEditForm: true
        },
        {
          label: 'Precondition',
          weight: 10.5,
          key: 'precondition',
          input: true,
          tooltip: 'Alow precodition from another component',
          type: 'checkbox',
          conditional: {
            json: { '===': [{ var: 'data.dataSrc' }, 'tables'] },
          },
          onChange(context: any) {
            console.log(context)
            console.log("Precondition: ", context.data.precondition);
          },
        },
        {
          type: 'datagrid',
          input: true,
          label: 'Precondition Map',
          key: 'preconditions',
          tooltip: 'Values to use as the data source. Labels are shown in the select field. Values are the corresponding values saved with the submission.',
          weight: 10.6,
          reorder: true,
          defaultValue: [{ 'column': '', 'componentData': '' }],
          components: [
            {
              label: 'Columns',
              key: 'column',
              input: true,
              type: 'select',
              data: {
                values: [
                ]
              },
              onChange(context: any) {
                console.log("Change column map ...")
                console.log(context)
              },
            },
            {
              label: 'Data Component',
              key: 'componentData',
              input: true,
              type: 'select',
              multiple: true,
              dataSrc: 'custom',
              data: {
                custom(context: any) {
                  return FormioUtils.getContextComponentsWithType(context, false, []);
                }
              },
              onChange(context: any) {
                console.log("Change value map ...")
                console.log(context)
              },
            },
          ],
          conditional: {
            json: { '===': [{ var: 'data.precondition' }, true] },
          },
          onChange(context: any) {
            console.log("Change precondition map ...")
            console.log(context)
          },
        },
        {
          type: 'select',
          key: 'listTable',
          label: 'Table Resource',
          weight: 10,
          input: true,
          tooltip: 'Select the table you want to retrieve data from',
          conditional: {
            json: { '===': [{ var: 'data.dataSrc' }, 'tables'] },
          },
          onChange(context: any) {

            let tableId = context.data.listTable
            let tableName = '';
            let keys: any[] = [];
            let value: {}[] = [];
            if (tableId) {
              axios.get<any>(`database-tables/${tableId}`).then(res => {
                let prototype = res.data[0]
                tableName = res.data.tableName;
                keys = res.data.columns;
                keys.forEach(item => {
                  value.push({ label: item.columnName, value: item.columnName })
                })
                const getComponent = context.utils.getComponent;
                const listColumnComponent = getComponent(context.form.components, 'listColumn', true);
                const column = getComponent(context.form.components, 'preconditions.column', true);
                _.set(listColumnComponent, 'data.values', value);
                _.set(column, 'data.values', value);
                // listColumnComponent.onChange();
                // column.onChange();
                context.data.data.url = `${process.env.REACT_APP_API_BASE_URL}/database-datas/select?page=0&size=20&tableName=${tableName}`;
              }).catch(error => {
                // message.error('Đã có lỗi xảy ra');
              })
            } else {
              context.data.data.url = '';
            }
          }
        },
        {
          label: "Display",
          weight: 10,
          components: [
            {
              label: "Basic",
              key: "basic",
              index: 0,
              components: [
                {
                  type: 'select',
                  key: 'listColumn',
                  label: 'Display template',
                  weight: 10,
                  input: true,
                  tooltip: 'Select the column',
                  onChange(context: any) {
                    const column = context.data.listColumn
                    const template = `<span>{{ item.${column} }}</span>`;
                    const tempalteComp = context.instance.parent.getComponent('template')
                    tempalteComp.setValue(template);
                  }
                },
              ]
            },
            {
              label: "Advanced",
              key: "advanced",
              components: [
                {
                  label: "Display template",
                  type: 'textarea',
                  input: true,
                  key: 'template',
                  editor: 'ace',
                  as: 'html',
                  rows: 3,
                  weight: 18,
                  tooltip: 'The HTML template for the result data items.',
                  allowCalculateOverride: true,
                  calculateValue: (context: any) => {
                    if (!context.data.template) {
                      if (context.instance && context.instance._currentForm.options.editComponent) {
                        return context.instance._currentForm.options.editComponent.template;
                      }
                    }
                    return context.data.template;
                  },
                },

              ]
            }
          ],
          key: "tabsdisplay",
          type: "tabs",
          input: true,
          tableView: true,
          conditional: {
            json: { '===': [{ var: 'data.dataSrc' }, 'tables'] },
          },
        },
        {
          key: 'defaultValue',
          weight: 11,
          overrideEditForm: true,
        },
        {
          key: 'template',
          conditional: {
            json: { '!==': [{ var: 'data.dataSrc' }, 'tables'] },
          },
        }
      ]
    }
  ]);
}