import React from 'react';
import {Route, Routes} from "react-router-dom";
import {RolesPage} from "./pages/RolesPage";
import {RoleDetailPage} from "./pages/RoleDetailPage";

function RolePages() {
    return (
        <Routes>
            <Route path="/">
                <Route index element={<RolesPage />} />
                <Route path={"/:roleId"} element={<RoleDetailPage />} />
            </Route>
        </Routes>
    );
}

export default  RolePages;