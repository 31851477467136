import moment from "moment";

const isPast = (date?: Date | number): boolean => {
  if (!date) {
    return false;
  }
  const now = moment();
  return moment(date).isBefore(now);
}

const isNearPast = (min: number, date?: Date | number): boolean => {
  if (!date) {
    return false;
  }
  const now = moment();
  const diff = moment(date).diff(now, "minutes");
  return diff <= 0 && -diff <= min;
}

const isNearFuture = (min: number, date?: Date | number): boolean => {
  if (!date) {
    return false;
  }
  const now = moment();
  const diff = moment(date).diff(now, "minutes");
  return diff >= 0 && diff <= min;
}

const isAroundPresent = (min: number, date?: Date | number): boolean => {
  return isNearPast(min, date) || isNearFuture(min, date)
}

const isCurrentYear = (date?: Date): boolean => {
  if (!date) {
    return false;
  }
  const now = moment();
  const diff = moment(date).diff(now, "years");
  return diff === 0
}

const isToday = (date?: Date): boolean => {
  if (!date) {
    return false;
  }
  const today = moment().format("DDMMYYYY");
  const dateFormatted = moment(date).format("DDMMYYYY");
  return today === dateFormatted
}

const generateDateBetween = (start: Date, end: Date): Date => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const generatePastDate = (maxDays: number = 100): Date => {
  const now = new Date();
  const pastDate = new Date();
  pastDate.setDate(pastDate.getDate() - maxDays);

  return generateDateBetween(pastDate, now);
}

const generateFutureDate = (maxDays: number = 100): Date => {
  const now = new Date();
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + maxDays);

  return generateDateBetween(now, futureDate);
}

const isBefore = (date1?: Date | number, date2?: Date | number): boolean => {
  if (!date1 || !date2) {
    return false;
  }

  return moment(date1).isBefore(date2);
}

const isAfter = (date1?: Date | number, date2?: Date | number): boolean => {
  if (!date1 || !date2) {
    return false;
  }

  return moment(date1).isAfter(date2);
}

export default {
  isPast,
  isToday,
  isNearPast,
  isNearFuture,
  isAroundPresent,
  isCurrentYear,
  generateDateBetween,
  generatePastDate,
  generateFutureDate,
  isBefore,
  isAfter
}