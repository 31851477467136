import { Components } from 'formiojs-llq';
import _ from 'lodash';
const url = '/database-tables?size=10&page=0'
const fileEditForm = (Components.components.file as any).editForm;
export default function (...extend: any) {
    return fileEditForm([
        {
            key: 'file',
            components: [
                {
                    key: 'storage',
                    ignore: true
                },
                {
                    key: 'dir',
                    ignore: true
                },
                {
                    key: 'fileNameTemplate',
                    ignore: true
                },
            ]
        },
        {
            key: 'data',
            components: [
                {
                    weight: 5,
                    type: 'textfield',
                    input: false,
                    key: 'folder',
                    label: 'Storage location',
                    customClass: "button-storage-location",
                    placeholder: 'Choose location',
                    tooltip: 'The label for this field that will appear next to it.',
                    validate: {
                        required: true
                    },
                }
            ]
        },
        {
            key: 'validation',
            ignore: true
        },
        {
            key: 'api',
            ignore: true
        },
        {
            key: 'conditional',
            ignore: true
        },
        {
            key: 'logic',
            ignore: true
        },
        {
            key: 'layout',
            ignore: true
        }
    ]);
}