import React, {FC, useEffect, useState} from "react";
import {Drawer, Tooltip} from "antd";
import {useAppDispatch, useAppSelector} from "../../hooks/redux-hooks";
import {hideSidebarCalendar, selectSidebarVisible} from "../../slices/global-action";
import './styles/calendar-sidebar.css';
import DateUtils from "../../utils/date.utils";

type Props = {}

const CalendarSideBar: FC<Props> = ({}) => {

    const sidebarVisible = useAppSelector(selectSidebarVisible);
    const [timeLabelRefs, setTimeLabelRefs] = useState<any>({});

    const dispatch = useAppDispatch();

    const onClose = () => {
        dispatch(hideSidebarCalendar());
    };

    const times: string[] = [];
    for (let i = 0; i < 24; i++) {
        if (i < 10) {
            times.push('0' + i + ':00');
        } else {
            times.push(i + ':00');
        }
    }

    const toTop = (date: Date) => {
      return 45 * (date.getHours() + date.getMinutes() / 60);
    }

    const events: any[] = [];
    const now = new Date();

    const pastHours = new Date();
    pastHours.setHours(now.getHours() - 2);

    const nextAHour = new Date();
    nextAHour.setHours(now.getHours() + 1);

    const next3HoursAndHaft = new Date();
    next3HoursAndHaft.setHours(now.getHours() + 3);
    next3HoursAndHaft.setMinutes(now.getMinutes() + 30);

    events.push({label: 'Phê duyệt ngân sách mua trang thiết bị', top: toTop(pastHours), deadline: pastHours});
    events.push({label: 'Phê duyệt hồ sơ chọn nhà cung cấp', top: toTop(now), deadline: now});
    events.push({label: 'Lên kế hoạch tuyển dụng Q3.2020', top: toTop(nextAHour), deadline: nextAHour});
    events.push({label: 'Lọc CV ứng tuyển vị trí NV kế toán', top: toTop(next3HoursAndHaft), deadline: next3HoursAndHaft});

    useEffect(() => {
        setTimeout(() => {
            const nowHour = new Date().getHours();
            const currentTimeLabelId = 'time_' + (nowHour < 10 ? '0' : '') + nowHour + "_00";
            const currentTimeLabel = document.getElementById(currentTimeLabelId);
            const topPos = currentTimeLabel?.offsetTop || 0;
            const wrapper = document.getElementById("sidebar-calendar-wrapper");
            wrapper?.scroll({top: topPos - 70, behavior: "smooth"})
        }, 0)
    }, [sidebarVisible]);

    const isCurrentTime = (timeLabel: string): boolean => {
        const nowHour = new Date().getHours();
        const currentTimeLabelId = (nowHour < 10 ? '0' : '') + nowHour + ":00";
        return currentTimeLabelId === timeLabel;
    }

    return (
        <Drawer
            title="Thời hạn công việc"
            placement="right"
            width={400}
            onClose={onClose}
            visible={sidebarVisible}
            closeIcon={<i className="fi fi-rr-cross"/>}
            className="sidebar-calendar-drawer"
        >
            <div className="sidebar-calendar-wrapper" id="sidebar-calendar-wrapper">
                <div className="sidebar-calendar">
                    <div className="sidebar-calendar-header">

                    </div>
                    <div className="sidebar-calendar-content">
                        <div className="time-label-wrapper">
                            {
                                times.map((label, index) =>
                                    <div
                                        className={`time-label ${isCurrentTime(label) ? 'active' : ''}`}
                                        key={index} id={`time_${label.replace(":", "_")}`}
                                    >
                                        <span>{label}</span>
                                    </div>
                                )
                            }
                        </div>
                        <div className="events-wrapper">
                            {
                                events.map((item, index) => (
                                    <Tooltip title={item.label} key={index} placement="left">
                                        <div
                                            className={`event-item ${DateUtils.isPast(item.deadline) ? 'overdue' : ''} ${DateUtils.isNearFuture(60, item.deadline) ? 'near-due' : ''}`}
                                            style={{top: item.top}} key={index}
                                        >
                                        </div>
                                    </Tooltip>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default CalendarSideBar;