import { UserTask } from "app/models/user-task";
import { ReactElement } from "react";

interface QuoteMap {
  [key: string]: Array<Quote>;
}

interface Quote {
  id: string;
  content: string;
  author: Author;
}

interface Author {
  id: string;
  name: string;
  descripyion: string;
  avatarUrl: ReactElement;
};

interface UserTaskMap {
  [authorName: string]: UserTask[];
}

const reorder = (list: UserTask[], startIndex: number, endIndex: number): UserTask[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default reorder;

export const reorderQuoteMap = ({
  quoteMap,
  source,
  destination,
}: {
  quoteMap: UserTaskMap;
  source: any;
  destination: any;
}): { quoteMap: UserTaskMap } => {
  debugger
  const current = [...quoteMap[source.droppableId]];
  const next = [...quoteMap[destination.droppableId]];
  const target = current[source.index];

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(current, source.index, destination.index);
    const result = {
      ...quoteMap,
      [source.droppableId]: reordered,
    };
    return {
      quoteMap: result,
    };
  }

  // moving to different list

  // remove from original
  current.splice(source.index, 1);
  // insert into next
  next.splice(destination.index, 0, target);

  const result = {
    ...quoteMap,
    [source.droppableId]: current,
    [destination.droppableId]: next,
  };

  return {
    quoteMap: result,
  };
};

// export function moveBetween({
//   list1,
//   list2,
//   source,
//   destination,
// }: {
//   list1: List;
//   list2: List;
//   source: any;
//   destination: any;
// }): { list1: List; list2: List } {
//   const newFirst = Array.from(list1.values);
//   const newSecond = Array.from(list2.values);

//   const moveFrom = source.droppableId === list1.id ? newFirst : newSecond;
//   const moveTo = moveFrom === newFirst ? newSecond : newFirst;

//   const [moved] = moveFrom.splice(source.index, 1);
//   moveTo.splice(destination.index, 0, moved);

//   return {
//     list1: {
//       ...list1,
//       values: newFirst,
//     },
//     list2: {
//       ...list2,
//       values: newSecond,
//     },
//   };
// }

interface List {
  id: string;
  values: Array<any>;
}