import {defineMessages} from 'react-intl';

export const scope = 'app.workflow';

export default defineMessages({
    'status.ACTIVE': {
        id: `${scope}.status.ACTIVE`,
        defaultMessage:
            'Active',
    },
    'status.INACTIVE': {
        id: `${scope}.status.INACTIVE`,
        defaultMessage:
            'InActive',
    },

    'version.status.DRAFT': {
        id: `${scope}.version.status.DRAFT`,
        defaultMessage:
            'Draft',
    },
    'version.status.PUBLISHED': {
        id: `${scope}.version.status.PUBLISHED`,
        defaultMessage:
            'Published',
    },
    'version.status.UNPUBLISHED': {
        id: `${scope}.version.status.UNPUBLISHED`,
        defaultMessage:
            'UnPublished',
    },

    'instance.status.ACTIVE': {
        id: `${scope}.instance.status.ACTIVE`,
        defaultMessage:
            'Active',
    },
    'instance.status.SUSPENDED': {
        id: `${scope}.instance.status.SUSPENDED`,
        defaultMessage:
            'Suspended',
    },

    'instance.status.COMPLETED': {
        id: `${scope}.instance.status.COMPLETED`,
        defaultMessage:
            'Completed',
    },

    // calculation -> calculate
    'calculation.calculate.operation.ADD': {
        id: `${scope}.calculation.calculate.operation.ADD`,
        defaultMessage:
            'Add',
    },
    'calculation.calculate.operation.SUBTRACT': {
        id: `${scope}.calculation.calculate.operation.SUBTRACT`,
        defaultMessage:
            'Subtract',
    },
    'calculation.calculate.operation.DIVIDE': {
        id: `${scope}.calculation.calculate.operation.DIVIDE`,
        defaultMessage:
            'Divide',
    },
    'calculation.calculate.operation.MULTIPLY': {
        id: `${scope}.calculation.calculate.operation.MULTIPLY`,
        defaultMessage:
            'Multiply',
    },
    'calculation.calculate.operation.EXP': {
        id: `${scope}.calculation.calculate.operation.EXP`,
        defaultMessage:
            'Exp',
    },
    'calculation.calculate.operation.ABS': {
        id: `${scope}.calculation.calculate.operation.ABS`,
        defaultMessage:
            'Abs',
    },
    'calculation.calculate.operation.SQRT': {
        id: `${scope}.calculation.calculate.operation.SQRT`,
        defaultMessage:
            'Sqrt',
    },
    'calculation.calculate.operation.FACTORIAL': {
        id: `${scope}.calculation.calculate.operation.FACTORIAL`,
        defaultMessage:
            'Factorial',
    },

    // roundNumber -> roundingPlace
    'calculation.roundNumber.roundingPlace.BILLIONS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.BILLIONS`,
        defaultMessage: 'Billions'
    },
    'calculation.roundNumber.roundingPlace.HUNDRED_MILLIONS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.HUNDRED_MILLIONS`,
        defaultMessage: 'Hundred millions'
    },
    'calculation.roundNumber.roundingPlace.TEN_MILLIONS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.TEN_MILLIONS`,
        defaultMessage: 'Ten millions'
    },
    'calculation.roundNumber.roundingPlace.MILLIONS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.MILLIONS`,
        defaultMessage: 'Millions'
    },
    'calculation.roundNumber.roundingPlace.HUNDRED_THOUSANDS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.HUNDRED_THOUSANDS`,
        defaultMessage: 'Hundred thousands'
    },
    'calculation.roundNumber.roundingPlace.TEN_THOUSANDS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.TEN_THOUSANDS`,
        defaultMessage: 'Ten thousands'
    },
    'calculation.roundNumber.roundingPlace.THOUSANDS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.THOUSANDS`,
        defaultMessage: 'Thousands'
    },
    'calculation.roundNumber.roundingPlace.HUNDREDS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.HUNDREDS`,
        defaultMessage: 'Hundreds'
    },
    'calculation.roundNumber.roundingPlace.TENS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.TENS`,
        defaultMessage: 'Tens'
    },
    'calculation.roundNumber.roundingPlace.ONES': {
        id: `${scope}.calculation.roundNumber.roundingPlace.ONES`,
        defaultMessage: 'Ones'
    },
    'calculation.roundNumber.roundingPlace.TENTHS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.TENTHS`,
        defaultMessage: 'Tenths'
    },
    'calculation.roundNumber.roundingPlace.HUNDREDTHS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.HUNDREDTHS`,
        defaultMessage: 'Hundredths'
    },
    'calculation.roundNumber.roundingPlace.THOUSANDTHS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.THOUSANDTHS`,
        defaultMessage: 'Thousandths'
    },
    'calculation.roundNumber.roundingPlace.TEN_THOUSANDTHS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.TEN_THOUSANDTHS`,
        defaultMessage: 'Ten thousandths'
    },
    'calculation.roundNumber.roundingPlace.HUNDRED_THOUSANDTHS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.HUNDRED_THOUSANDTHS`,
        defaultMessage: 'Hundred thousandths'
    },
    'calculation.roundNumber.roundingPlace.MILLIONTHS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.MILLIONTHS`,
        defaultMessage: 'Millionths'
    },
    'calculation.roundNumber.roundingPlace.TEN_MILLIONTHS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.TEN_MILLIONTHS`,
        defaultMessage: 'Ten millionths'
    },
    'calculation.roundNumber.roundingPlace.HUNDRED_MILLIONTHS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.HUNDRED_MILLIONTHS`,
        defaultMessage: 'Hundred millionths'
    },
    'calculation.roundNumber.roundingPlace.BILLIONTHS': {
        id: `${scope}.calculation.roundNumber.roundingPlace.BILLIONTHS`,
        defaultMessage: 'Billionths'
    },

    // generateHash -> hashType
    'calculation.generateHash.hashType.MD5': {
        id: `${scope}.calculation.generateHash.hashType.MD5`,
        defaultMessage: 'MD5'
    },
    'calculation.generateHash.hashType.SHA1': {
        id: `${scope}.calculation.generateHash.hashType.SHA1`,
        defaultMessage: 'SHA1'
    },
    'calculation.generateHash.hashType.SHA256': {
        id: `${scope}.calculation.generateHash.hashType.SHA256`,
        defaultMessage: 'SHA256'
    },
    'calculation.generateHash.hashType.SHA512': {
        id: `${scope}.calculation.generateHash.hashType.SHA512`,
        defaultMessage: 'SHA512'
    },

    'approval.approvalPolicy.ALL': {
        id: `${scope}.approval.approvalPolicy.ALL`,
        defaultMessage: 'All'
    },
    'approval.approvalPolicy.ANY': {
        id: `${scope}.approval.approvalPolicy.ANY`,
        defaultMessage: 'Any'
    },
    'approval.approvalPolicy.PLURALITY': {
        id: `${scope}.approval.approvalPolicy.PLURALITY`,
        defaultMessage: 'Plurality'
    },
});
