import React, {FC} from "react";
import PageToolbar from "../../../app/components/common/PageToolbar";

type Props = {
}

const RoleMemberToolbar: FC<Props> = () => {

    return (
        <>
            <PageToolbar
                title={
                    <div className="user-page-title">
                        <h4>Thông tin chức vụ</h4>
                    </div>
                }
            />
        </>
    )
}

export default RoleMemberToolbar;