export default function (ctx) {
    var __t, __p = '';
    if (ctx.permissionMode) {
        __p += '<div class="form-permission" ref="form">\n    ' +
            ((__t = (ctx.form)) == null ? '' : __t) +
            '\n  </div>\n</div>\n';
    } else {
        __p += '<div class="formio builder row formbuilder">\n  <div class="col-xs-4 col-sm-3 col-md-2 formcomponents">\n    ' +
            ((__t = (ctx.sidebar)) == null ? '' : __t) +
            '\n  </div>\n  <div class="col-xs-8 col-sm-9 col-md-10 formarea" ref="form">\n    ' +
            ((__t = (ctx.form)) == null ? '' : __t) +
            '\n  </div>\n</div>\n';
    }
    return __p
}