import React, {FC} from 'react';
import moment from 'moment'
import DateUtils from "../../utils/date.utils";

type Props = {
    value?: any;
    fullFormat?: string;
    dateAndMonthFormat?: string;
    dateAndTimeFormat?: string;
    timeFormat?: string;
};

const TimeFromNow: FC<Props> = ({
                                    value,
                                    fullFormat = "DD/MM/YY",
                                    dateAndMonthFormat = "DD/MM",
                                    dateAndTimeFormat = "DD/MM HH:mm",
                                    timeFormat = "HH:mm"
                                }) => {

    if (!value) {
        return <></>
    }

    if (DateUtils.isAroundPresent(60, value) && DateUtils.isCurrentYear(value)) {
        return (
            <>{moment(value).fromNow()}</>
        )
    }

    if (DateUtils.isAroundPresent(60 * 24, value) && DateUtils.isToday(value)) {
        return (
            <>{moment(value).format(timeFormat)}</>
        )
    }

    if (DateUtils.isAroundPresent(60 * 24, value) && DateUtils.isCurrentYear(value)) {
        return (
            <>{moment(value).format(dateAndTimeFormat)}</>
        )
    }

    if (DateUtils.isCurrentYear(value)) {
        return (
            <>{moment(value).format(dateAndMonthFormat)}</>
        )
    }

    return (
        <>{moment(value).format(fullFormat)}</>
    );
};

export default TimeFromNow;
