import {defineMessages} from 'react-intl';

export const scope = 'app.single-task';

export default defineMessages({
  'type.TASK': {
    id: `${scope}.type.TASK`,
    defaultMessage:
        'Task',
  },
  'type.APPROVAL': {
    id: `${scope}.type.APPROVAL`,
    defaultMessage:
        'Approval',
  },

  'status.DRAFT': {
    id: `${scope}.status.DRAFT`,
    defaultMessage:
        'Draft',
  },
  'status.PUBLISHED': {
    id: `${scope}.status.PUBLISHED`,
    defaultMessage:
        'Published',
  },
  'status.UNPUBLISHED': {
    id: `${scope}.status.UNPUBLISHED`,
    defaultMessage:
        'Unpublished',
  }
});
