import { FC } from 'react'
import './styles/page-sidebar.css'
import { Link, useLocation } from 'react-router-dom'
import darkLogo from '../../../assets/images/logo.svg'
import { Menu } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import PagesConstant from '../../constants/pages.constant'
import { hasAnyPermission, hasPermission } from '../../utils/auth.utils'

type Props = {}

const PageSideBar: FC<Props> = ({}) => {
  const menuItems: ItemType[] = [
    {
      label: <Link to={`/`}>Dashboard</Link>,
      key: 'dashboard',
      icon: <i className='fi fi-sr-pulse'></i>
    },
    {
      label: <Link to={`/tasks`}>Công việc</Link>,
      key: 'tasks',
      icon: <i className='fi fi-sr-layers'></i>
    },
    {
      label: <Link to={`/single-tasks`}>Nhiệm vụ</Link>,
      icon: <i className='fi fi-sr-shopping-bag'></i>,
      key: 'single-tasks'
    }
  ]

  if (hasPermission('read:drive')) {
    menuItems.push({
      label: <Link to={`/drive`}>EzDrive</Link>,
      key: 'drive',
      icon: <i className='fi fi-sr-cube'></i>
    })
  }

  if (hasPermission('read:workflow')) {
    menuItems.push({
      label: <Link to={`/workflows`}>Quy trình</Link>,
      key: 'workflows',
      icon: <i className='fi fi-rr-vector'></i>
    })
  }

  if (hasPermission('read:form')) {
    menuItems.push({
      label: <Link to={`/forms`}>Biểu mẫu</Link>,
      key: 'forms',
      icon: <i className='fi fi-sr-tool-marquee'></i>
    })
  }

  const fileMenuItems = []
  if (hasPermission('read:file')) {
    fileMenuItems.push({
      label: 'Files',
      key: 'files',
      className: 'sub-menu',
      icon: (
        <Link to={`/files`}>
          <i className='fi fi-sr-cloud-upload-alt'></i>
        </Link>
      )
    })

    fileMenuItems.push({
      label: 'Loại file',
      key: 'file-categories',
      className: 'sub-menu',
      icon: (
        <Link to={`/files/categories`}>
          <i className='fi fi-sr-apps'></i>
        </Link>
      )
    })

    fileMenuItems.push({
      label: 'Chính sách lưu trữ',
      key: 'retention-policies',
      className: 'sub-menu',
      icon: (
        <Link to={`/files/retention-policies`}>
          <i className='fi fi-sr-disk'></i>
        </Link>
      )
    })
  }

  if (hasPermission('read:file')) {
    menuItems.push({
      label: 'Files',
      key: 'menu-files',
      icon: <i className='fi fi-sr-cloud-upload-alt'></i>,
      children: fileMenuItems
    })
  }

  const userMenuItems = []
  if (hasPermission('read:user')) {
    userMenuItems.push({
      label: 'Người dùng',
      key: 'users',
      className: 'sub-menu',
      icon: (
        <Link to={`/users`}>
          <i className='fi fi-sr-users'></i>
        </Link>
      )
    })
  }
  if (hasPermission('read:group')) {
    userMenuItems.push({
      label: 'Nhóm người dùng',
      key: 'groups',
      className: 'sub-menu',
      icon: (
        <Link to={`/groups`}>
          <i className='fi fi-rr-users-alt'></i>
        </Link>
      )
    })
  }
  if (hasPermission('read:role')) {
    userMenuItems.push({
      label: 'Chức vụ',
      key: 'roles',
      className: 'sub-menu',
      icon: (
        <Link to={`/roles`}>
          <i className='fi fi-sr-shield-check'></i>
        </Link>
      )
    })
  }
  if (hasPermission('read:user')) {
    userMenuItems.push({
      label: 'Danh mục đơn vị',
      key: 'catalog-units',
      className: 'sub-menu',
      icon: (
        <Link to={`/catalog-units`}>
          <i className='fi fi-sr-shield-check'></i>
        </Link>
      )
    })
  }
  if (hasPermission('read:user')) {
    userMenuItems.push({
      label: 'Danh mục phòng ban',
      key: 'department-units',
      className: 'sub-menu',
      icon: (
        <Link to={`/catalog-departments`}>
          <i className='fi fi-sr-shield-check'></i>
        </Link>
      )
    })
  }
  if (hasPermission('read:user')) {
    userMenuItems.push({
      label: 'Danh mục chức vụ',
      key: 'catalog-positions',
      className: 'sub-menu',
      icon: (
        <Link to={`/catalog-positions`}>
          <i className='fi fi-sr-shield-check'></i>
        </Link>
      )
    })
  }

  if (hasAnyPermission(['read:user', 'read:group', 'read:role'])) {
    menuItems.push({
      label: 'Người dùng',
      key: 'user-role',
      icon: <i className='fi fi-sr-following'></i>,
      children: userMenuItems
    })
  }

  if (hasPermission('read:single_task_config')) {
    menuItems.push({
      label: <Link to={`/single-task-config`}>Quản lý mẫu nhiệm vụ</Link>,
      icon: <i className='fi fi-sr-shopping-bag-add'></i>,
      key: 'single-task-config'
    })
  }

  if (hasPermission('read:database')) {
    menuItems.push({
      label: <Link to={`/databases`}>Database</Link>,
      icon: <i className='fi fi-sr-database'></i>,
      key: 'database'
    })
  }

  if (hasPermission('read:app')) {
    menuItems.push({
      label: <Link to={`/apps`}>App</Link>,
      icon: <i className='fi fi-sr-apps'></i>,
      key: 'app'
    })
  }

  menuItems.push({
    label: <Link to={`/workflow-setup`}>Workflow Steps</Link>,
    icon: <i className='fi fi-sr-stars'></i>,
    key: 'workflow-setup'
  })

  const bottomMenuItems: ItemType[] = []

  if (hasPermission('read:setting')) {
    const settingItems = [
      {
        label: 'Ngày nghỉ',
        key: 'public-holidays',
        className: 'sub-menu',
        icon: (
          <Link to={`/settings/public-holidays`}>
            <i className='fi fi-rr-calendar'></i>
          </Link>
        )
      }
    ]

    bottomMenuItems.push({
      label: <Link to={`/settings`}>Cài đặt</Link>,
      icon: <i className='fi fi-sr-settings'></i>,
      key: 'settings',
      children: settingItems
    })
  }

  const location = useLocation()
  const page = PagesConstant.resolvePage(location.pathname)

  let selectedMenus: string[] = page?.key ? [page.key] : []
  let bottomSelectedMenus: string[] = page?.key ? [page.key] : []

  return (
    <div className='page-sidebar'>
      <div className='logo'>
        <Link to={`/`} className='logo'>
          <img src={darkLogo} alt='Workflowlaez' />
        </Link>
      </div>
      <div className='sidebar-menu pull-top'>
        <Menu
          theme='light'
          defaultSelectedKeys={selectedMenus}
          selectedKeys={selectedMenus}
          mode='inline'
          items={menuItems}
          style={{ width: 99 }}
          inlineCollapsed
        />
      </div>
      <div className='bottom-sidebar-menu pull-bottom'>
        <Menu
          theme='light'
          defaultSelectedKeys={bottomSelectedMenus}
          selectedKeys={bottomSelectedMenus}
          mode='inline'
          items={bottomMenuItems}
          style={{ width: 99 }}
          inlineCollapsed
        />
      </div>
    </div>
  )
}

export default PageSideBar
