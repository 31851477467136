import React, {useState} from 'react';
import '../styles/index.css';
import GroupToolbar from "../components/GroupToolbar";
import {Empty, Table} from "antd";
import EzCard from "../../../app/components/common/EzCard";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import GroupsTableConfig from "../configs/groups-table.config";
import {useNavigate} from "react-router-dom";
import {Group} from "../../../app/models/group";
import useGroups from "../../../app/hooks/group/useGroups";

export function GroupsPage() {

    const [page, setPage] = useState(0);
    const {data, refetch: reloadGroups} = useGroups({page, size: 10});
    const navigate = useNavigate();

    const menuItems: ItemType[] = [
        {key: 'detail', label: 'Chi tiết'},
        {key: 'remove', label: 'Lưu trữ', danger: true},
    ];

    const onMenuClick = (event: {key: string, data: Group}) => {
      if (event.key === 'detail') {
          navigate("/groups/" + event.data.id);
          return;
      }
    }

    const columns = GroupsTableConfig.columns(menuItems, onMenuClick);

    const onPageChange = (page: number) => {
        setPage(page - 1);
    }

    const isEmpty = (data?.totalElements || 0) === 0;

    return (
        <>
            <div className="page-content-wrapper">
                <GroupToolbar total={data?.totalElements || 0} reloadGroups={reloadGroups}/>
                <div className="page-content box group-page-content">
                    <EzCard title={<h3>Danh sách nhóm người dùng</h3>} footer={isEmpty}>
                        {
                            isEmpty && <Empty />
                        }
                        {
                            !isEmpty &&
                            <Table
                                bordered
                                className="data-table"
                                columns={columns}
                                dataSource={data?.content || []}
                                rowKey={'id'}
                                pagination={{
                                    total: data?.totalElements,
                                    onChange: onPageChange
                                }}
                            />
                        }
                    </EzCard>
                </div>
            </div>
        </>
    );
}