import BreadcrumbItem from "../models/common/breadcrumb";

export type Page = {
    title: string;
    key: string;
    breadcrumb: BreadcrumbItem[];
}

export type PageMatcher = {
    pathMatch: string;
    exact: boolean;
    page: Page;
}

export const PAGES: PageMatcher[] = [
    {
        pathMatch: '/',
        exact: true,
        page: {
            title: 'Dashboard',
            key: 'dashboard',
            breadcrumb: [
                {title: 'Dashboard'}
            ]
        }
    },
    {
        pathMatch: '/tasks',
        exact: false,
        page: {
            title: 'Công việc',
            key: 'tasks',
            breadcrumb: [
                {title: 'Công việc'}
            ]
        }
    },
    {
        pathMatch: '/forms',
        exact: false,
        page: {
            title: 'Biểu mẫu',
            key: 'forms',
            breadcrumb: [
                {title: 'Biểu mẫu'}
            ]
        }
    },
    {
        pathMatch: '/workflows',
        exact: false,
        page: {
            title: 'Quy trình',
            key: 'workflows',
            breadcrumb: [
                {title: 'Quy trình'}
            ]
        }
    },
    {
        pathMatch: '/running-workflows',
        exact: false,
        page: {
            title: 'Thực hiện quy trình',
            key: 'running-workflows',
            breadcrumb: [
                {title: 'Thực hiện quy trình'}
            ]
        }
    },
    {
        pathMatch: '/files',
        exact: true,
        page: {
            title: 'Files',
            key: 'files',
            breadcrumb: [
                {title: 'Files'}
            ]
        }
    },
    {
        pathMatch: '/files/categories',
        exact: true,
        page: {
            title: 'Loại file',
            key: 'file-categories',
            breadcrumb: [
                {title: 'Loại file'}
            ]
        }
    },
    {
        pathMatch: '/files/retention-policies',
        exact: true,
        page: {
            title: 'Chính sách lưu trữ',
            key: 'retention-policies',
            breadcrumb: [
                {title: 'Chính sách lưu trữ'}
            ]
        }
    },
    {
        pathMatch: '/drive',
        exact: false,
        page: {
            title: 'EzDrive',
            key: 'drive',
            breadcrumb: [
                {title: 'EzDrive'}
            ]
        }
    },
    {
        pathMatch: '/users',
        exact: false,
        page: {
            title: 'Người dùng',
            key: 'users',
            breadcrumb: [
                {title: 'Người dùng'}
            ]
        }
    },
    {
        pathMatch: '/roles',
        exact: false,
        page: {
            title: 'Chức vụ',
            key: 'roles',
            breadcrumb: [
                {title: 'Chức vụ'}
            ]
        }
    },
    {
        pathMatch: '/groups',
        exact: false,
        page: {
            title: 'Nhóm người dùng',
            key: 'groups',
            breadcrumb: [
                {title: 'Nhóm người dùng'}
            ]
        }
    },
    {
        pathMatch: '/settings',
        exact: true,
        page: {
            title: 'Cài đặt',
            key: 'settings',
            breadcrumb: [
                {title: 'Cài đặt'}
            ]
        }
    },
    {
        pathMatch: '/reports',
        exact: false,
        page: {
            title: 'Báo cáo',
            key: 'reports',
            breadcrumb: [
                {title: 'Báo cáo'}
            ]
        }
    },
    {
        pathMatch: '/authorization',
        exact: false,
        page: {
            title: 'Ủy quyền',
            key: 'authorization',
            breadcrumb: [
                {title: 'Ủy quyền'}
            ]
        }
    },
    {
        pathMatch: '/single-task-config',
        exact: false,
        page: {
            title: 'Mẫu nhiệm vụ',
            key: 'single-task-config',
            breadcrumb: [
                {title: 'Mẫu nhiệm vụ'}
            ]
        }
    },
    {
        pathMatch: '/single-tasks',
        exact: false,
        page: {
            title: 'Nhiệm vụ',
            key: 'single-tasks',
            breadcrumb: [
                {title: 'Nhiệm vụ'}
            ]
        }
    },
    {
        pathMatch: '/settings/public-holidays',
        exact: false,
        page: {
            title: 'Ngày nghỉ',
            key: 'public-holidays',
            breadcrumb: [
                {title: 'Cài đặt'},
                {title: 'Ngày nghỉ'}
            ]
        }
    },
    {
        pathMatch: '/databases',
        exact: false,
        page: {
            title: 'Database',
            key: 'database',
            breadcrumb: [
                {title: 'Database'}
            ]
        }
    },
    {
        pathMatch: '/apps',
        exact: false,
        page: {
            title: 'App',
            key: 'app',
            breadcrumb: [
                {title: 'App'}
            ]
        }
    },
    {
        pathMatch: '/workflow-setup',
        exact: false,
        page: {
            title: 'Workflow Setup',
            key: 'workflow-setup',
            breadcrumb: [
                {title: 'Workflow Setup'}
            ]
        }
    },
    {
        pathMatch: '/catalog-units',
        exact: false,
        page: {
            title: 'Danh mục đơn vị',
            key: 'catalog-units',
            breadcrumb: [
                {title: 'Danh mục đơn vị'}
            ]
        }
    },
    {
        pathMatch: '/catalog-departments',
        exact: false,
        page: {
            title: 'Danh mục phòng ban',
            key: 'catalog-departments',
            breadcrumb: [
                {title: 'Danh mục phòng ban'}
            ]
        }
    },
    {
        pathMatch: '/catalog-positions',
        exact: false,
        page: {
            title: 'Danh mục chức vụ',
            key: 'catalog-positions',
            breadcrumb: [
                {title: 'Danh mục chức vụ'}
            ]
        }
    },
];

const resolvePage = (path: string): Page | undefined => {
    for (const matcher of PAGES) {
        if (matcher.exact && (matcher.pathMatch === path || matcher.pathMatch + "/" === path)) {
            return matcher.page;
        }
        if (!matcher.exact && path.startsWith(matcher.pathMatch)) {
            return matcher.page;
        }
    }
    return undefined;
}

export default {
    resolvePage: resolvePage
}