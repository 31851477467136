import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {getLanguage, saveLanguage} from "../services/LanguageStorageService";
import {SupportedLanguage} from "../models/supported-language";

export interface GlobalActionState {
  sidebarCalendarVisible: boolean;
  spotlightSearchVisible: boolean;
  language: SupportedLanguage;
}

const initialState: GlobalActionState = {
  sidebarCalendarVisible: false,
  spotlightSearchVisible: false,
  language: getLanguage('en')
};

export const globalActionSlice = createSlice({
  name: 'globalAction',
  initialState,
  reducers: {
    showSidebarCalendar: (state) => {
      state.sidebarCalendarVisible = true;
    },
    hideSidebarCalendar: (state) => {
      state.sidebarCalendarVisible = false;
    },
    showSpotlightSearch: (state) => {
      state.spotlightSearchVisible = true;
    },
    hideSpotlightSearch: (state) => {
      state.spotlightSearchVisible = false;
    },
    changeLanguage: (state, action: PayloadAction<SupportedLanguage>) => {
      state.language = action.payload;
      saveLanguage(action.payload);
    },
  },
});

export const { showSidebarCalendar, hideSidebarCalendar, showSpotlightSearch, hideSpotlightSearch, changeLanguage } = globalActionSlice.actions;

export const selectSidebarVisible = (state: RootState) => state.globalAction.sidebarCalendarVisible;
export const selectSpotlightSearchVisible = (state: RootState) => state.globalAction.spotlightSearchVisible;
export const selectLanguage = (state: RootState) => state.globalAction.language

const { reducer } = globalActionSlice;
export default reducer;
