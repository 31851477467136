import {ColumnsType} from "antd/es/table";
import {Role} from "../../../app/models/user";
import {Link} from "react-router-dom";
import ActionButton from "../../../app/components/common/ActionButton";
import React from "react";

const columns = (menuItems: any, onMenuClick: (event: any) => void): ColumnsType<Role> => [
    {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        width: 50,
        align: "center",
        render: (value, record, index) => (
            <>{index + 1}</>
        )
    },
    {
        title: 'Tên chức vụ',
        dataIndex: 'name',
        key: 'name',
        render: (value, record) => (
            <Link to={`/roles/${record.id}`}>
                <h4 className="text-underline-hover text-dark fs-16px no-margin">{record.name}</h4>
            </Link>
        )
    },
    {
        title: 'Mô tả',
        dataIndex: 'description',
        key: 'description'
    },
    {
        title: 'Có thể ủy quyền',
        dataIndex: 'authorizationAllow',
        key: 'authorizationAllow',
        align: 'center',
        width: 150,
        render: (value, record) => (
            <div className="role-authorization-allow">
                {
                    record.authorizationEnable && <i className="fi fi-sr-check text-primary"></i>
                }
            </div>
        )
    },
    {
        title: '##',
        dataIndex: 'actions',
        key: 'actions',
        align: "center",
        width: 30,
        render: (value, record) => (
            <ActionButton menuItems={menuItems} onClick={onMenuClick} data={record}/>
        )
    },
];

export default {
    columns
}