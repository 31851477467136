import React, {FC} from 'react';
import {Breadcrumb} from 'antd';
import BreadcrumbItem from "../../models/common/breadcrumb";
import {Link} from "react-router-dom";
import './styles/page-breadcrumb.css';

type Props = {
    title: string;
    breadCrumb?: BreadcrumbItem[]
};

const PageBreadcrumb: FC<Props> = ({title, breadCrumb = []}) => {

    return (
        <div className="page-breadcrumb-wrapper">
            <h2 className="page-title">{title}</h2>
            <span className="breadcrumb-space"></span>
            <Breadcrumb className="page-breadcrumb">
                <Breadcrumb.Item>
                    <Link to={`/`}>Home</Link>
                </Breadcrumb.Item>
                {
                    breadCrumb &&
                    breadCrumb.map((item, index) => (
                        <Breadcrumb.Item key={index}>
                            {item.url && <Link to={`${item.url}`}>{item.title}</Link>}
                            {!item.url && <>{item.title}</>}
                        </Breadcrumb.Item>
                    ))
                }
            </Breadcrumb>
        </div>
    );
};

export default PageBreadcrumb;
