import React, {FC} from 'react';
import {Form, Input, Modal, Switch} from "antd";
import {Role} from "../../../app/models/user";

type Props = {
    visible: boolean;
    onUpdate: (values: any) => void;
    onCancel: () => void;
    role: Role;
}

const UpdateRoleModal: FC<Props> = ({visible, onUpdate, onCancel, role}) => {

    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            title="Cập nhật chức vụ"
            okText="Cập nhật"
            cancelText="Cancel"
            onCancel={onCancel}
            width={880}
            style={{top: '20px'}}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        onUpdate(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{...role}}
            >
                <Form.Item
                    name="name"
                    label="Tên chức vụ"
                    rules={[{ required: true, message: 'Trường này là bắt buộc' }]}
                >
                    <Input placeholder="Tên chức vụ"/>
                </Form.Item>
                <Form.Item name="description" label="Mô tả">
                    <Input.TextArea placeholder="Mô tả"/>
                </Form.Item>
                <Form.Item
                    name="authorizationEnable"
                    label="Có thể ủy quyền"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default UpdateRoleModal;