import React, {FC} from "react";
import './styles/page-toolbar.css';

const TYPES = ['primary', 'secondary', 'success', 'info', 'danger', 'warning', 'dark'] as const;
export type BadgeType = typeof TYPES[number];

type Props = {
    type: BadgeType,
    outline?: boolean;
    light?: boolean;
}

const EzBadge: FC<Props> = ({type = 'primary', outline = false, light = false, children}) => {

    return (
        <span className={`badge badge${light ? '-light' : ''}-${type} ${outline ? 'badge-outline' : ''}`}>
            {children}
        </span>
    );
}

export default EzBadge;