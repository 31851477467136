import axios from "axios";
import tokenStorageService, {getToken} from "../services/TokenStorageService";
import history from "../utils/history.utils";
import {message} from "antd";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.request.use(function (config) {
    const token = tokenStorageService.getToken();
    if (token) {
        config.headers = config.headers || {};
        config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    config.baseURL = process.env.REACT_APP_API_BASE_URL + "/";
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    if (error.response?.status === 401) {
        tokenStorageService.removeToken();
        history.replace("/logout");
        history.go(0);
    }
    if (error.response?.status === 403) {
        message.error("Bạn không có quyền thực hiện thao tác này!").then();
    }

    return Promise.reject(error);
});