import {AddGroupMemberCommand, GroupMemberSearchRequest, RemoveGroupMemberCommand} from "../models/group";
import axios from "axios";
import {User} from "../models/user";
import PageResponse from "../models/common/page-response";
import QueryUtils from "../utils/query.utils";

const API_URL = 'groups';

export function search(request: GroupMemberSearchRequest): Promise<PageResponse<User>> {
    const queryStr =  QueryUtils.toQuery(request);
    return axios.get<PageResponse<User>>(`${API_URL}/${request.groupId}/members?${queryStr}`)
        .then(response => response.data);
}

export function add(request: AddGroupMemberCommand): Promise<any> {
    return axios.post<any>(`${API_URL}/${request.groupId}/members`, request)
        .then(response => response.data);
}

export function remove(request: RemoveGroupMemberCommand): Promise<any> {
    return axios.delete<any>(`${API_URL}/${request.groupId}/members/${request.userId}`)
        .then(response => response.data);
}

const groupMemberService = {
    search,
    add,
    remove
};

export default groupMemberService;