import React, {FC} from 'react';
import {Form, Input, Modal} from "antd";

type Props = {
    visible: boolean;
    onCreate: (values: any) => void;
    onCancel: () => void;
}

const CreateGroupModal: FC<Props> = ({visible, onCreate, onCancel}) => {

    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            title="Thêm mới nhóm người dùng"
            okText="Thêm mới"
            cancelText="Cancel"
            onCancel={onCancel}
            width={880}
            style={{top: '20px'}}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <Form.Item
                    name="name"
                    label="Tên nhóm"
                    rules={[{ required: true, message: 'Trường này là bắt buộc' }]}
                >
                    <Input placeholder="Tên nhóm"/>
                </Form.Item>
                <Form.Item name="description" label="Mô tả">
                    <Input.TextArea placeholder="Mô tả"/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CreateGroupModal;