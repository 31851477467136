import React, {FC} from "react";
import './styles/ez-priority.css'
import {Priority} from "../../models/common/common";
import I18n from "./I18n";
import {Tooltip} from "antd";
import EzStatus from "./EzStatus";

export const PriorityIcons = (value: Priority) => {
    switch (value) {
        case 'LOW':
            return <i className="fi fi-sr-angle-down"></i>;
        case 'MEDIUM':
            return <i className="fi fi-sr-minus"></i>;
        case 'HIGH':
            return <i className="fi fi-sr-angle-up"></i>;
        case 'VERY_HIGH':
            return <i className="fi fi-sr-chevron-double-up"></i>;
    }
}

type Props = {
    value?: Priority;
    tooltipVisible?: boolean;
    iconOnly?: boolean;
}

const EzPriority: FC<Props> = ({value, tooltipVisible = false, iconOnly = false}) => {

    if (!value) {
        return <></>
    }

    return (
        <Tooltip title={tooltipVisible && <I18n type="common" prefix="priority" messageKey={value}/>}>
            <span className="ez-priority-wrap">
                <EzStatus status={value} light>
                    <span className="ez-priority">
                        {PriorityIcons(value)}
                        {
                            !iconOnly &&
                            <I18n type="common" prefix="priority" messageKey={value}/>
                        }
                    </span>
                </EzStatus>
            </span>
        </Tooltip>
    )
}

export default EzPriority;