import {defineMessages} from 'react-intl';

export const scope = 'app.space';

export default defineMessages({
  'label': {
    id: `${scope}.label`,
    defaultMessage: 'Space',
  },
  'buttons.add': {
    id: `${scope}.buttons.add`,
    defaultMessage: 'Add space',
  },
  'buttons.andMoreMember': {
    id: `${scope}.buttons.andMoreMember`,
    defaultMessage: 'And {number} other',
  },
  'scope.PUBLIC': {
    id: `${scope}.scope.PUBLIC`,
    defaultMessage: 'Public',
  },
  'scope.PERSONAL': {
    id: `${scope}.scope.PERSONAL`,
    defaultMessage: 'Personal',
  },
  'scope.RESTRICTED': {
    id: `${scope}.scope.RESTRICTED`,
    defaultMessage: 'Restricted',
  },
  'member.role.OWNER': {
    id: `${scope}.member.role.OWNER`,
    defaultMessage: 'Owner',
  },
  'member.role.ADMIN': {
    id: `${scope}.member.role.ADMIN`,
    defaultMessage: 'Admin',
  },
  'member.role.MEMBER': {
    id: `${scope}.member.role.MEMBER`,
    defaultMessage: 'Member',
  },
});
