import React, {useState} from 'react';
import '../styles/index.css';
import RoleToolbar from "../components/RoleToolbar";
import {Empty, Table} from "antd";
import EzCard from "../../../app/components/common/EzCard";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import useRoles from "../../../app/hooks/role/useRoles";
import RolesTableConfig from "../configs/roles-table.config";
import {useNavigate} from "react-router-dom";
import {Role} from "../../../app/models/user";

export function RolesPage() {

    const [page, setPage] = useState(0);
    const {data, refetch: reloadRoles} = useRoles({page, size: 10});
    const navigate = useNavigate();

    const menuItems: ItemType[] = [
        {key: 'detail', label: 'Chi tiết'},
        {key: 'remove', label: 'Lưu trữ', danger: true},
    ];

    const onMenuClick = (event: {key: string, data: Role}) => {
      if (event.key === 'detail') {
          navigate("/roles/" + event.data.id);
          return;
      }
    }

    const columns = RolesTableConfig.columns(menuItems, onMenuClick);

    const onPageChange = (page: number) => {
        setPage(page - 1);
    }

    const isEmpty = (data?.totalElements || 0) === 0;

    return (
        <>
            <div className="page-content-wrapper">
                <RoleToolbar total={data?.totalElements || 0} reloadRoles={reloadRoles}/>
                <div className="page-content box role-page-content">
                    <EzCard title={<h3>Danh sách chức vụ</h3>} footer={isEmpty}>
                        {
                            isEmpty && <Empty />
                        }
                        {
                            !isEmpty &&
                            <Table
                                bordered
                                className="data-table"
                                columns={columns}
                                dataSource={data?.content || []}
                                rowKey={'id'}
                                pagination={{
                                    total: data?.totalElements,
                                    onChange: onPageChange
                                }}
                            />
                        }
                    </EzCard>
                </div>
            </div>
        </>
    );
}