import axios from 'axios';
import PageResponse from "../models/common/page-response";
import QueryUtils from "../utils/query.utils";
import {Notification, NotificationSearchRequest} from "../models/notification";

const API_URL = 'notifications';

export function search(request?: NotificationSearchRequest): Promise<PageResponse<Notification>> {
    const queryStr =  QueryUtils.toQuery(request);
    return axios.get<PageResponse<Notification>>(`${API_URL}?${queryStr}`)
        .then(response => response.data);
}

const notificationService = {
    search
};

export default notificationService;