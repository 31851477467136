import React, {FC, useState} from "react";
import {Button, message} from "antd";
import PageToolbar from "../../../app/components/common/PageToolbar";
import CreateRoleModal from "./CreateRoleModal";
import useCreateRole from "../../../app/hooks/role/useCreateRole";

type Props = {
    total: number;
    reloadRoles: () => void
}

const RoleToolbar: FC<Props> = ({total, reloadRoles}) => {

    const [visible, setVisible] = useState(false);
    const {mutate: createRole} = useCreateRole();

    const onCreate = (values: any) => {
        createRole(values, {
            onSuccess: () => {
                message.success("Thêm mới chức vụ thành công!").then();
                reloadRoles();
            },
            onError: () => {
                message.error("Đã có lỗi xảy ra vui lòng thử lại!").then();
            }
        });
        setVisible(false);
    };

    return (
        <>
            <PageToolbar
                title={
                    <div className="user-page-title">
                        <h4>{total} chức vụ</h4>
                    </div>
                }
                action={
                    <div className="user-page-action">
                        <Button type="primary" shape="round" onClick={() => setVisible(true)}>Thêm mới</Button>
                    </div>
                }
            />
            <CreateRoleModal
                visible={visible}
                onCancel={() => setVisible(false)}
                onCreate={onCreate}
            />
        </>
    )
}

export default RoleToolbar;