import React, {FC} from 'react';
import StringUtils from "../../utils/string.utils";

type Props = {
  value: string;
  scale?: number;
  lastWord?: boolean;
  defaultValue?: any;
};

const ShortTextFormatter: FC<Props> = ({value, scale = 1, defaultValue, lastWord}) => {

  if (!value) {
    return <>{defaultValue}</>;
  }

  let process = value;

  if (lastWord) {
    process = StringUtils.lastWordOf(value);
  }

  if (process.length < scale) {
    return <>{process.substring(0, scale - 1)}</>;
  }

  return <>{process.substring(0, scale)}</>;
};

export default ShortTextFormatter;
