import React, {FC} from "react";
import './styles/page-toolbar.css';
import {FormattedMessage} from "react-intl";
import commonMessages from "../../../translations/messages/common";
import workflowMessages from "../../../translations/messages/workflow";
import spaceMessages from "../../../translations/messages/space";
import folderMessages from "../../../translations/messages/folder";
import accountMessages from "../../../translations/messages/account";
import formMessages from "../../../translations/messages/form";
import userMessages from "../../../translations/messages/user";
import userTaskMessages from "../../../translations/messages/user-task";
import fileStorageMessages from "../../../translations/messages/file-storage";
import singleTaskMessages from "../../../translations/messages/single-task";

const common: any = commonMessages;
const workflow: any = workflowMessages;
const space: any = spaceMessages;
const folder: any = folderMessages;
const account: any = accountMessages;
const form: any = formMessages;
const user: any = userMessages;
const userTask: any = userTaskMessages;
const fileStorage: any = fileStorageMessages;
const singleTask: any = singleTaskMessages;

type Props = {
    type?: 'workflow' | 'common' | 'folder' | 'space' | 'account' | 'form' | 'user' | 'user-task' | 'file-storage' | 'single-task';
    prefix?: string;
    messageKey?: string;
}

const I18n: FC<Props> = ({type = 'common', prefix , messageKey}) => {

    if (!messageKey) {
        return <>___</>
    }

    let id = '';
    if (prefix) {
        id += `${prefix}.`
    }
    id += `${messageKey}`;

    switch (type) {
        case "workflow":
            return <FormattedMessage {...workflow[id]} defaultMessage={"___"}/>
        case "space":
            return <FormattedMessage {...space[id]} defaultMessage={"___"}/>
        case "folder":
            return <FormattedMessage {...folder[id]} defaultMessage={"___"}/>
        case "account":
            return <FormattedMessage {...account[id]} defaultMessage={"___"}/>
        case "form":
            return <FormattedMessage {...form[id]} defaultMessage={"___"}/>
        case "user":
            return <FormattedMessage {...user[id]} defaultMessage={"___"}/>
        case "user-task":
            return <FormattedMessage {...userTask[id]} defaultMessage={"___"}/>
        case "file-storage":
            return <FormattedMessage {...fileStorage[id]} defaultMessage={"___"}/>
        case "single-task":
            return <FormattedMessage {...singleTask[id]} defaultMessage={"___"}/>
        case "common":
        default:
            return <FormattedMessage {...common[id]} defaultMessage={"___"}/>
    }
}

export default I18n;