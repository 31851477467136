import React from "react";
import {Outlet} from "react-router-dom";
import './styles/index.css';
import Footer from "./Footer";
import CalendarSideBar from "./CalendarSideBar";
import SpotlightSearchBox from "./SpotlightSearchBox";
import Header from "./Header";
import PageSideBar from "./PageSideBar";

export function PrimaryLayout() {

    return (
        <>
            <div className="primary-layout layout-wrapper">
                <PageSideBar />
                <div className="page-wrapper">
                    <Header />
                    <div className="page-container">
                        <Outlet/>
                    </div>
                    <Footer />
                </div>
            </div>
            <CalendarSideBar />
            <SpotlightSearchBox />
        </>
    );
}