import React, { FC, useEffect, useState } from "react";
import './styles/simple-picker.css';
import { Folder, Resource, Space } from "../../models/drive";
import EzDrivePicker from "./EzDrivePicker";
import { Button, Tooltip } from "antd";
import useGetFolderByCode from "../../hooks/folder/useGetFolderByCode";

type Props = {
    onChange?: (value?: string) => void;
    value?: string
    showPath?: boolean
}

const SimpleFolderCodePicker: FC<Props> = ({ value: folderCode, onChange = () => { }, showPath = false }) => {
    const [folderPickerVisible, setFolderPickerVisible] = useState(false);
    const [selectedFolderCode, setSelectedFolderCode] = useState<string | undefined>();
    const [folderPath, setFolderPath] = useState<string>();

    const { mutate: getFolderByCode, data: selectedFolder } = useGetFolderByCode();

    useEffect(() => {
        setSelectedFolderCode(folderCode);
    }, [folderCode]);

    useEffect(() => {
        if (!selectedFolderCode) {
            return;
        }
        getFolderByCode(selectedFolderCode);
    }, [selectedFolderCode])

    const onSelectFolder = (space?: Space, folder?: Folder, resource?: Resource, path?: string) => {
        setFolderPath(path)
        onChange(folder?.code);
        setSelectedFolderCode(folder?.code);
        setFolderPickerVisible(false);
    }

    const onRemove = () => {
        onSelectFolder(undefined, undefined);
    }

    const isSelected = selectedFolderCode;

    return (
        <>
            <div className="simple-picker-wrapper">
                {
                    isSelected &&
                    <div className="simple-picker flex justify-content-space-between align-items-center">
                        <span className="simple-picker__file-name text-dark">
                            {showPath ? folderPath : (selectedFolder?.code +  "-" + selectedFolder?.title)}
                        </span>
                        <Tooltip title="Xóa" placement="left">
                            <span className="simple-picker__btn-remove flex align-items-center">
                                <Button className="btn-icon" size={"small"} type="link" onClick={onRemove}>
                                    <i className="fi fi-rr-cross-small"></i>
                                </Button>
                            </span>
                        </Tooltip>
                    </div>
                }
                {
                    !isSelected &&
                    <Button type="dashed" shape="round" onClick={() => setFolderPickerVisible(true)}>
                        Chọn thư mục
                    </Button>
                }
            </div>
            <EzDrivePicker
                visible={folderPickerVisible}
                onClose={() => setFolderPickerVisible(false)}
                selectionModes={['FOLDER']}
                showResources={false}
                onSelect={onSelectFolder}
            />
        </>
    )
}

export default SimpleFolderCodePicker;