import React, {FC} from "react";
import './styles/page-toolbar.css';
import EzBadge, {BadgeType} from "./EzBadge";

type Props = {
    status?: string,
    outline?: boolean;
    light?: boolean;
}

const statusesMap: {[key: string]: BadgeType} = {
    'PUBLISHED': 'success',
    'UNPUBLISHED': 'danger',
    'DRAFT': 'warning',

    'ACTIVE': 'primary',
    'INACTIVE': 'warning',
    'SUSPENDED': 'danger',
    'COMPLETED': 'success',

    'PUBLIC': 'success',
    'INTERNAL': 'warning',
    'RESTRICTED': 'info',

    'LOW': 'primary',
    'MEDIUM': 'info',
    'HIGH': 'warning',
    'VERY_HIGH': 'danger',
}

const EzStatus: FC<Props> = ({status = '', outline = false, light = false, children}) => {

    let type = statusesMap[status.toUpperCase()];

    if (!type) {
        type = 'primary';
    }

    return (
        <EzBadge type={type} light={light} outline={outline}>
            {children}
        </EzBadge>
    );
}

export default EzStatus;