export const DARK_COLORS = ['#145c8b', '#005fbb', '#c74983', '#00a7ff', '#ecca57', '#d96800', '#f282bf', '#f76b8b', '#126269'];

const random = (input?: string, type?: 'dark') => {
    const firstCharCode = input?.charCodeAt(0) || 0;
    const len = DARK_COLORS.length;
    return DARK_COLORS[firstCharCode % len];
}

export default {
    random
}