import axios from 'axios';
import {
    DownloadFileCommand,
    FileStorage,
    FileStorageSearchRequest, GenerateDownloadPreSignedUrlByCodeCommand,
    GenerateDownloadPreSignedUrlCommand,
    PreSignedUrl,
    UploadFileCommand
} from "../models/file-storage";
import PageResponse from "../models/common/page-response";
import QueryUtils from "../utils/query.utils";
import { IdResponse } from "../models/common/id.response";
import FormDataUtils from "../utils/form-data.utils";
import { saveAs } from 'file-saver';

const API_URL = 'files';

export function generateDownloadPreSignedUrl(request: GenerateDownloadPreSignedUrlCommand): Promise<PreSignedUrl> {
    return axios.post<PreSignedUrl>(`${API_URL}/download-urls`, request)
        .then(response => response.data);
}

export function generateDownloadPreSignedUrlByCode(request: GenerateDownloadPreSignedUrlByCodeCommand): Promise<PreSignedUrl> {
    return axios.post<PreSignedUrl>(`${API_URL}/download-urls-by-code`, request)
        .then(response => response.data);
}

export function search(request?: FileStorageSearchRequest): Promise<PageResponse<FileStorage>> {
    const queryStr = QueryUtils.toQuery(request);
    return axios.get<PageResponse<FileStorage>>(`${API_URL}?${queryStr}`)
        .then(response => response.data);
}

export function findById(id: number): Promise<FileStorage> {
    return axios.get<FileStorage>(`${API_URL}/${id}`)
        .then(response => response.data);
}

export function upload(request: UploadFileCommand): Promise<IdResponse<number>> {
    const formData = FormDataUtils.toFormData(request);
    return axios.post<IdResponse<number>>(`${API_URL}`, formData)
        .then(response => response.data);
}

export function uploadWL(request: any): Promise<IdResponse<number>> {
    const formData = FormDataUtils.toFormData(request);
    return axios.post<IdResponse<number>>(`workflow-instances/formio/upload`, formData)
        .then(response => response.data);
}

export function download(request: DownloadFileCommand): Promise<any> {
    return axios.post<any>(`${API_URL}/download`, request, { responseType: 'blob' });
}

const fileStorageService = {
    generateDownloadPreSignedUrl,
    generateDownloadPreSignedUrlByCode,
    search,
    findById,
    upload,
    uploadWL,
    download
};

export default fileStorageService;