import { Components } from 'formiojs-llq';
import editForm from './FileCustom.form'
import _ from 'lodash';
import fileStorageService from 'app/services/FileStorageService';
import ReactDOM from 'react-dom';
import SimpleFolderCodePicker from 'app/components/drive/SimpleFolderCodePicker';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { saveAs } from 'file-saver';
import { message } from 'antd';
const file = (Components as any).components.file;

export default class FileCustom extends (file as any) {

    uploadFileStorage(files: any, fileUpload: any) {
        const currentURL = window.location.href;

        // Phân tích URL để trích xuất taskCode
        const urlParts = currentURL.split('/');
        let taskCode = urlParts[urlParts.length - 1];
        while (/[^\w\s]$/.test(taskCode)) {
            taskCode = taskCode.slice(0, -1);
        }
        const request = {
            file: files,
            folderCode: this.component.folder,
            taskCode: taskCode,
        }
        return fileStorageService.uploadWL(request);
    }

    // Schema render form
    static schema(...extend: any[]) {
        return file.schema({
            type: 'filecustom',
            label: 'File Custom',
            key: 'fileCustom',
            folder: '',
            storage: 'base64'
        });
    }

    // Form Builder information
    static get builderInfo() {
        return {
            title: 'File - LLQ',
            group: 'basic',
            icon: 'file',
            weight: 100,
            documentation: '/userguide/form-building/form-components#select-box',
            schema: FileCustom.schema()
        };
    }

    public static editForm = editForm;

    render() {
        return super.render()
    }

    init() {
        super.init();
        var button = document.querySelector('.button-storage-location [ref="element"] input') as HTMLInputElement;
        const setFolderCode = (code?: string) => {
            if (button instanceof HTMLInputElement) {
                button.value = code ? code : '';
                var changeEvent = new Event('input');
                button.dispatchEvent(changeEvent);
            }
        }
        if (button) {
            button.setAttribute("readonly", "readonly");
            button.style.display = 'none';
            var parent: any = button.parentNode;
            var existingDiv = parent.querySelector('#folder-picker');
            if (!existingDiv) {
                var div = document.createElement('div');
                div.setAttribute('id', 'folder-picker');
                parent.appendChild(div);
                const queryClient = new QueryClient();
                const element = React.createElement(SimpleFolderCodePicker, { onChange: setFolderCode, showPath: true });
                const wrappedElement = React.createElement(QueryClientProvider, { client: queryClient }, element);
                ReactDOM.render(wrappedElement, document.getElementById('folder-picker'));
            }
        }
    }

    attach(element: any) {
        this.loadRefs(element, {
            fileDrop: 'single',
            removeLink: 'multiple',
            fileProcessingLoader: 'single',
        })
        if (this.refs.fileProcessingLoader)
            this.refs.fileProcessingLoader.style.display = 'none'
        if (this.refs.fileDrop)
            this.refs.fileDrop.setAttribute('style', 'position: relative; padding: 15px; border: 2px dashed #ddd; text-align: center;');
        this.refs.removeLink.forEach((removeLink: any, index: string | number) => {
            this.addEventListener(removeLink, 'click', (event: { preventDefault: () => void; }) => {
                const fileInfo = this.dataValue[index];
                this.deleteFile(fileInfo);
                event.preventDefault();
                this.splice(index);
                this.redraw();
            });
        });
        return super.attach(element)
    }

    getFile(fileInfo: any) {
        fileStorageService.download({ "fileId": fileInfo.id })
            .then(res => {
                saveAs(res.data, fileInfo.name)
            })
            .catch(error => {
                message.error("Download file không thành công!")
            })
    }

    deleteFile(fileInfo: any) {
        // this.dataValue.filter((item: any) => item !== fileInfo)
    }

    upload(files: any) {
        if (this.builderMode || this.previewMode) {
            return
        }

        // khong upload neu trong preview
        if (document.querySelector('[ref="preview"]')) {
            return
        }

        if (!this.component.multiple) {
            if (this.statuses.length) {
                this.statuses = [];
            }
            files = Array.prototype.slice.call(files, 0, 1);
        }

        if (this.component.storage && files && files.length) {
            this.fileDropHidden = true;

            // files is not really an array and does not have a forEach method, so fake it.
            /* eslint-disable max-statements */
            Array.prototype.forEach.call(files, async (file) => {
                const fileName = file.name;
                const escapedFileName = file.name ? file.name.replaceAll('<', '&lt;').replaceAll('>', '&gt;') : file.name;
                const fileUpload = {
                    abort: () => null,
                    originalName: escapedFileName,
                    name: fileName,
                    size: file.size,
                    status: 'info',
                    message: this.t('Processing file. Please wait...'),
                    hash: '',
                    id: '',
                };

                if (this.root.form.submissionRevisions === 'true') {
                    this.statuses.push(fileUpload);
                    this.redraw();
                    this.emit('fileUploadingEnd');
                }

                // Check if file with the same name is being uploaded
                if (!this.filesUploading) {
                    this.filesUploading = [];
                }
                const fileWithSameNameUploading = this.filesUploading.some((fileUploading: string) => fileUploading === file.name);
                this.filesUploading.push(file.name);

                const fileWithSameNameUploaded = this.dataValue.some((fileStatus: { originalName: any; }) => fileStatus.originalName === file.name);
                const fileWithSameNameUploadedWithError = this.statuses.findIndex((fileStatus: { originalName: any; status: string; }) =>
                    fileStatus.originalName === file.name
                    && fileStatus.status === 'error'
                );

                if (fileWithSameNameUploaded || fileWithSameNameUploading) {
                    fileUpload.status = 'error';
                    fileUpload.message = this.t(`File with the same name is already ${fileWithSameNameUploading ? 'being ' : ''}uploaded`);
                }

                if (fileWithSameNameUploadedWithError !== -1) {
                    this.statuses.splice(fileWithSameNameUploadedWithError, 1);
                    this.redraw();
                }

                // Check file pattern
                if (this.component.filePattern && !this.validatePattern(file, this.component.filePattern)) {
                    fileUpload.status = 'error';
                    fileUpload.message = this.t('File is the wrong type; it must be {{ pattern }}', {
                        pattern: this.component.filePattern,
                    });
                }
                // Check file minimum size
                if (this.component.fileMinSize && !this.validateMinSize(file, this.component.fileMinSize)) {
                    fileUpload.status = 'error';
                    fileUpload.message = this.t('File is too small; it must be at least {{ size }}', {
                        size: this.component.fileMinSize,
                    });
                }

                // Check file maximum size
                if (this.component.fileMaxSize && !this.validateMaxSize(file, this.component.fileMaxSize)) {
                    fileUpload.status = 'error';
                    fileUpload.message = this.t('File is too big; it must be at most {{ size }}', {
                        size: this.component.fileMaxSize,
                    });
                }

                // Get a unique name for this file to keep file collisions from occurring.
                const dir = this.interpolate(this.component.dir || '');
                const { fileService } = this;
                if (!fileService) {
                    fileUpload.status = 'error';
                    fileUpload.message = this.t('File Service not provided.');
                }

                if (this.root.form.submissionRevisions !== 'true') {
                    this.statuses.push(fileUpload);
                    this.redraw();
                }

                if (fileUpload.status !== 'error') {
                    if (this.component.privateDownload) {
                        file.private = true;
                    }
                    const { storage, options = {} } = this.component;
                    const url = this.interpolate(this.component.url, { file: fileUpload });
                    let groupKey = null;
                    let groupPermissions = null;

                    //Iterate through form components to find group resource if one exists
                    this.root.everyComponent((element: any) => {
                        if (element.component?.submissionAccess || element.component?.defaultPermission) {
                            groupPermissions = !element.component.submissionAccess ? [
                                {
                                    type: element.component.defaultPermission,
                                    roles: [],
                                },
                            ] : element.component.submissionAccess;

                            groupPermissions.forEach((permission: { type: string; }) => {
                                groupKey = ['admin', 'write', 'create'].includes(permission.type) ? element.component.key : null;
                            });
                        }
                    });
                    fileUpload.message = this.t('Starting upload...');
                    this.redraw();
                    this.uploadFileStorage(file, fileUpload).then(result => {
                        const index = this.statuses.indexOf(fileUpload);
                        if (index !== -1) {
                            this.statuses.splice(index, 1);
                        }
                        fileUpload.id = result.id.toString();
                        delete fileUpload.message
                        if (!this.hasValue()) {
                            this.dataValue = [];
                        }
                        this.dataValue.push(fileUpload);
                        _.pull(this.filesUploading, fileUpload.originalName);
                        this.fileDropHidden = false;
                        this.redraw()
                    }).catch(
                        error => {
                            fileUpload.status = 'error';
                            fileUpload.message = 'Upload error!'
                            this.fileDropHidden = false;
                            this.redraw()
                        }
                    )
                }
                else {
                    this.filesUploading.splice(this.filesUploading.indexOf(file.name), 1);
                }
            });
        }
    }
}