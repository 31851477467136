import React from 'react';
import {Route, Routes} from "react-router-dom";
import {GroupsPage} from "./pages/GroupsPage";
import {GroupDetailPage} from "./pages/GroupDetailPage";

function GroupPages() {
    return (
        <Routes>
            <Route path="/">
                <Route index element={<GroupsPage />} />
                <Route path={"/:groupId"} element={<GroupDetailPage />} />
            </Route>
        </Routes>
    );
}

export default  GroupPages;