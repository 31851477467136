import {defineMessages} from 'react-intl';

export const scope = 'app.user-task';

export default defineMessages({
  'type.TASK': {
    id: `${scope}.type.TASK`,
    defaultMessage:
        'Task',
  },
  'type.APPROVAL': {
    id: `${scope}.type.APPROVAL`,
    defaultMessage:
        'Approval',
  },

  'checklist.status.DONE': {
    id: `${scope}.checklist.status.DONE`,
    defaultMessage:
        'Done',
  },
  'checklist.status.IN_PROGRESS': {
    id: `${scope}.checklist.status.IN_PROGRESS`,
    defaultMessage:
        'In Progress',
  },
  'checklist.status.OPEN': {
    id: `${scope}.checklist.status.OPEN`,
    defaultMessage:
        'Open',
  },
  'checklist.status.SKIP': {
    id: `${scope}.checklist.status.SKIP`,
    defaultMessage:
        'Skip',
  }
});
