import React, {FC} from "react";
import './styles/action-button.css';
import {Dropdown, Menu} from "antd";
import {ItemType} from "antd/lib/menu/hooks/useItems";

type Props = {
    menuItems: ItemType[];
    data?: any;
    onClick?: (event: {key: string, data?: any}) => void;
    disabled?: boolean;
}

const ActionButton: FC<Props> = ({menuItems, onClick = () => {}, data, disabled}) => {

    const menu = () => (
        <Menu items={menuItems} onClick={e => onClick({key: e.key, data})}/>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']} disabled={disabled}>
            <span className={`btn-action ${disabled ? 'disabled' : ''}`}>
              <i className="fi fi-rr-apps"/>
            </span>
        </Dropdown>
    )
}

export default ActionButton;