import React, {FC, useEffect, useState} from "react";
import './styles/page-toolbar.css';

type Props = {
    title?: React.ReactElement;
    action?: React.ReactElement;
}

const PageToolbar: FC<Props> = ({title, action}) => {

    const [shadow, setShadow] = useState(false);

    useEffect(() => {
        const onScroll = () => setShadow(window.scrollY > 10)

        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    return (
        <div className={`page-toolbar ${shadow  ? 'shadow' : ''}`}>
            {
                title &&
                <div className="page-toolbar-title">
                    {title}
                </div>
            }
            {
                action &&
                <div className="page-toolbar-action">
                    {action}
                </div>
            }
        </div>
    )
}

export default PageToolbar;