import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import AuthService from "../services/AuthService";
import {RootState} from "../store";
import TokenStorageService from "../services/TokenStorageService";
import JwtUtils from "../utils/jwt.utils";

const token = TokenStorageService.getToken();
let uid = undefined;
let permissions: string[] = [];
if (token) {
    const jwt = JwtUtils.parseJwt(token);
    uid = jwt.sub;
    permissions = TokenStorageService.getPermission();
}
export interface AuthState {
    isAuthenticated: boolean;
    userId: string;
    permissions: string[];
}

const initialState: AuthState = {
    isAuthenticated: !!token,
    userId: uid,
    permissions: permissions
};

export const getToken = createAsyncThunk(
    'auth/getToken',
    async (code: string, {rejectWithValue}) => {
        try {
            const response = await AuthService.login(code);
            return response.accessToken;
        } catch (e) {
            rejectWithValue(e);
        }
    }
);

export const logout = createAsyncThunk("auth/logout", async () => {
    await AuthService.logout();
});

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getToken.fulfilled, (state) => {
                state.isAuthenticated = true;
                const token = TokenStorageService.getToken();
                if (token) {
                    state.userId = JwtUtils.parseJwt(token).sub;
                    state.permissions = TokenStorageService.getPermission();  
                }
            })
            .addCase(getToken.rejected, (state, action) => {
                state.isAuthenticated = false;
            })
            .addCase(logout.fulfilled, (state) => {
                state.isAuthenticated = false;
            });
    },
});

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectUserId = (state: RootState) => state.auth.userId;
export const selectPermissions = (state: RootState) => state.auth.permissions;

const {reducer} = authSlice;
export default reducer;
