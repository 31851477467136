import React, {FC, useEffect} from 'react';
import {Form, Input, message, Modal} from "antd";

type Props = {
  visible: boolean;
  onClose: () => void;
}

const ChangePasswordModal: FC<Props> = ({visible, onClose}) => {

  const [form] = Form.useForm();

  useEffect(() => {
      return () => {
          form.resetFields();
      };
  }, [visible, form]);

  const onOK = () => {
    form
        .validateFields()
        .then(values => {
          onSubmit(values);
          form.resetFields();
        })
        .catch(info => {
          console.log('Validate Failed:', info);
        });
  }

  const onSubmit = (values: any) => {
    onClose();
  }

  return (
    <Modal
      title="Change password"
      visible={visible}
      onCancel={onClose}
      getContainer={false}
      onOk={onOK}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="oldPassword"
          label="Mật khẩu hiện tại"
          rules={[
            {required: true, message: 'Trường này không được để trống!'}
          ]}
        >
          <Input.Password placeholder="Mật khẩu hiện tại"/>
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="Mật khẩu mới"
          rules={[{required: true, message: 'Trường này không được để trống!'},
            {
              pattern: /^(.){8,}$/,
              message: "Mật khẩu phải ít nhất 8 ký tự"
            }]}
        >
          <Input.Password placeholder="Mật khẩu mới"/>
        </Form.Item>
      </Form>
    </Modal>
  )
};

export default ChangePasswordModal;
