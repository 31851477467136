const lastWordOf = (value: string): string => {
  if (!value) {
    return '';
  }
  const arr = value.split(' ');
  return arr[arr.length - 1];
}

const vietnameseToLatin = (input: string): string => {
  let result: string = '';
  const diacritics = ["á", "à", "ả", "ã", "ạ", "ă", "ắ", "ằ", "ẳ", "ẵ", "ặ", "â", "ấ", "ầ", "ẩ", "ẫ", "ậ", "é", "è", "ẻ", "ẽ", "ẹ", "ê", "ế", "ề", "ể", "ễ", "ệ", "í", "ì", "ỉ", "ĩ", "î", "ị", "ó", "ò", "ỏ", "õ", "ô", "ọ", "ơ", "ớ", "ờ", "ở", "ỡ", "ô", "ợ", "ố", "ồ", "ổ", "ỗ", "ộ", "ú", "ù", "ủ", "ũ", "ư", "ụ", "ứ", "ừ", "ử", "ữ", "ự", "ý", "ỳ", "ỷ", "ỹ", "î", "ị"];

  const plainCharacters = ["a", "a", "a", "a", "a", "a", "a", "a", "a", "a", "a", "a", "a", "a", "a", "a", "a", "e", "e", "e", "e", "e", "e", "e", "e", "e", "e", "e", "i", "i", "i", "i", "i", "i", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "o", "u", "u", "u", "u", "u", "u", "u", "u", "u", "u", "u", "y", "y", "y", "y", "i", "i"];
  for (let index = 0; index < diacritics.length; index++) {
    input = input.replace(diacritics[index], plainCharacters[index]);
  }
  return input;
}

export default {
  lastWordOf,
  vietnameseToLatin
}