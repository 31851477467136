import React, { FC, useEffect, useRef, useState } from "react";
import { Col, Empty, Modal, Row } from "antd";
import useSpaces from "../../hooks/space/useSpaces";
import { Folder, Resource, ResourceType, Space } from "../../models/drive";
import useFolders from "../../hooks/folder/useFolders";
import './styles/ez-drive-picker.css';
import useFolderChain from "../../hooks/folder/useFolderChain";
import useResources from "../../hooks/resource/useResources";

type Props = {
    visible: boolean;
    onClose: () => void;
    onSelect?: (space?: Space, folder?: Folder, resource?: Resource, path?: string) => void;
    resourceTypes?: ResourceType[];
    showResources?: boolean;
    selectionModes?: ('FOLDER' | 'SPACE' | 'RESOURCE')[];
}

const EzDrivePicker: FC<Props> = ({
    visible,
    onClose,
    resourceTypes,
    showResources,
    onSelect = () => { },
    selectionModes = ['RESOURCE', 'FOLDER', 'SPACE']
}) => {

    const [selectedSpace, setSelectedSpace] = useState<Space>();
    const [selectedFolder, setSelectedFolder] = useState<Folder>();
    const [selectedResource, setSelectedResource] = useState<Resource>();
    const folderPath = useRef<string>();

    const { data: spaces } = useSpaces({ size: 1000 });
    const { data: folders } = useFolders({ size: 1000, spaceId: selectedSpace?.id, parentId: selectedFolder?.id });
    const { data: folderChain } = useFolderChain(selectedFolder?.id);
    const { data: resources } = useResources({ size: 1000, types: resourceTypes, spaceId: selectedSpace?.id, folderId: selectedFolder?.id }, !showResources);


    useEffect(() => {
        resetAll();
    }, [visible]);

    const onSelectSpace = (space: Space) => {
        setSelectedSpace(space);
    }
    const onSelectFolder = (folder: Folder) => {
        setSelectedFolder(folder);
    }
    const onSelectResource = (resource: Resource) => {
        setSelectedResource(resource);
    }
    const resetFolder = () => {
        setSelectedFolder(undefined);
        setSelectedResource(undefined);
    }
    const resetAll = () => {
        setSelectedSpace(undefined);
        setSelectedFolder(undefined);
        setSelectedResource(undefined);
    }

    const onOk = () => {
        let path: string = selectedSpace?.title ? (selectedSpace.title + folderPath.current) : '';
        onSelect(selectedSpace, selectedFolder, selectedResource, path);
    }

    const spacesVisible = !selectedSpace;
    const foldersVisible = !!selectedSpace;
    const resourcesVisible = !!selectedSpace && showResources;

    let okText = undefined;
    let okEnable = (selectedResource && selectionModes?.includes('RESOURCE'))
        || (selectedFolder && selectionModes?.includes('FOLDER'))
        || (selectedSpace && selectionModes?.includes('SPACE'));

    if (selectedResource && selectionModes?.includes('RESOURCE')) {
        switch (selectedResource.resourceType) {
            case "FILE":
                okText = 'Chọn file này';
                break;
            case "FORM":
                okText = 'Chọn biểu mẫu này';
                break;
            case "WORKFLOW":
                okText = 'Chọn quy trình này';
                break;
        }
    } else if (selectedFolder && selectionModes?.includes('FOLDER')) {
        okText = 'Chọn thư mục này';
    } else if (selectedSpace && selectionModes?.includes('SPACE')) {
        okText = 'Chọn space này';
    }

    return (
        <Modal
            title="EzDrive"
            visible={visible}
            onCancel={onClose}
            width={1100}
            style={{ top: '20px' }}
            className="drive-picker__modal"
            onOk={onOk}
            okText={okText}
            okButtonProps={{ disabled: !okEnable }}
        >
            <div className="drive-picker__breadcrumb">
                <span onClick={resetAll} className="flex align-items-center gap-1">
                    <i className="fi fi-rr-cube"></i>
                    Space
                </span>
                {
                    selectedSpace &&
                    <>
                        <span className="separator">/</span>
                        <span
                            onClick={resetFolder}
                        >
                            {selectedSpace.title}
                        </span>
                    </>
                }
                {
                    folderChain?.map((item, index) => {
                        if(index === 0) folderPath.current = '';
                        folderPath.current = folderPath.current + " > " + item.title;
                        return <React.Fragment key={index}>
                            <span className="separator">/</span>
                            <span key={index} onClick={() => onSelectFolder(item)}>
                                {item.title}
                            </span>
                        </React.Fragment>
                    }
                    )
                }
            </div>
            <div className="drive-picker__content-wrapper">
                <div className="drive-picker__content">
                    {
                        spacesVisible && (spaces?.totalElements || 0) !== 0 &&
                        <>
                            <div className="drive-picker__title">
                                <span className="text-gray-500 fs-12px">Space</span>
                            </div>
                            <Row gutter={{ lg: 25, md: 15, sm: 12, xs: 7 }}>
                                {
                                    spacesVisible && spaces?.content?.map((item, index) =>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }} key={index}>
                                            <div
                                                onClick={() => onSelectSpace(item)}
                                                className="drive-picker__item drive-picker__item--space"
                                            >
                                                <div className="drive-picker__item__icon flex align-items-center">
                                                    <i className="fi fi-rr-cube"></i>
                                                </div>
                                                <div className="drive-picker__item__content">
                                                    <h3 className="no-margin fs-14px text-dark text-nowrap text-overflow-ellipsis">{item.title}</h3>
                                                    {
                                                        item.description &&
                                                        <span className="text-gray-500 fs-13px text-nowrap text-overflow-ellipsis">{item.description}</span>
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                }
                            </Row>
                        </>
                    }

                    {
                        foldersVisible && (folders?.totalElements || 0) !== 0 &&
                        <>
                            <div className="drive-picker__title">
                                <span className="text-gray-500 fs-12px">Thư mục</span>
                            </div>
                            <Row gutter={{ lg: 25, md: 15, sm: 12, xs: 7 }} className="mb-2">
                                {
                                    foldersVisible && folders?.content?.map((item, index) =>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }} key={index}>
                                            <div
                                                onClick={() => onSelectFolder(item)}
                                                className="drive-picker__item drive-picker__item--folder"
                                            >
                                                <div className="drive-picker__item__icon flex align-items-center">
                                                    <i className="fi fi-sr-folder" />
                                                </div>
                                                <div className="drive-picker__item__content">
                                                    <h3 className="no-margin fs-14px text-dark text-nowrap text-overflow-ellipsis">{item.title}</h3>
                                                    {
                                                        item.description &&
                                                        <span className="text-gray-500 fs-13px text-nowrap text-overflow-ellipsis">{item.description}</span>
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                }
                            </Row>
                        </>
                    }

                    {
                        resourcesVisible && (resources?.totalElements || 0) !== 0 &&
                        <>
                            <div className="drive-picker__title">
                                <span className="text-gray-500 fs-12px">Resources</span>
                            </div>
                            <Row gutter={{ lg: 25, md: 15, sm: 12, xs: 7 }}>
                                {
                                    resourcesVisible && resources?.content?.map((item, index) =>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }} key={index}>
                                            <div
                                                onClick={() => onSelectResource(item)}
                                                className={`drive-picker__item drive-picker__item--resource ${item.id === selectedResource?.id ? 'selected' : 'a'}`}
                                            >
                                                <div className="drive-picker__item__icon flex align-items-center">
                                                    {item.resourceType === 'WORKFLOW' && <i className="fi fi-sr-vector"></i>}
                                                    {item.resourceType === 'FORM' && <i className="fi fi-sr-tool-marquee"></i>}
                                                    {item.resourceType === 'FILE' && <i className="fi fi fi-sr-cloud-upload-alt"></i>}
                                                </div>
                                                <div className="drive-picker__item__content">
                                                    <h3 className="no-margin fs-14px text-dark text-nowrap text-overflow-ellipsis">{item.title}</h3>
                                                    {
                                                        item.description &&
                                                        <span className="text-gray-500 fs-13px text-nowrap text-overflow-ellipsis">{item.description}</span>
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                }
                            </Row>
                        </>
                    }
                </div>

                {
                    (!spacesVisible || (spaces?.totalElements || 0) === 0)
                    && (!foldersVisible || (folders?.totalElements || 0) === 0)
                    && (!resourcesVisible || (resources?.totalElements || 0) === 0)
                    &&
                    <div className="drive-picker__empty">
                        <Empty />
                    </div>
                }
            </div>
        </Modal>
    )
}

export default EzDrivePicker;