import React, {FC, useEffect, useState} from 'react';
import './styles/header.css';
import HeaderMenu from "./HeaderMenu";
import UserActionMenu from "./UserActionMenu";
import PageBreadcrumb from "./PageBreadcrumb";
import {useLocation} from "react-router-dom";
import PagesConstant, {Page} from "../../constants/pages.constant";

type Props = {}

const Header: FC<Props> = () => {

    const location = useLocation();
    const [shadow, setShadow] = useState(false);

    useEffect(() => {
        const onScroll = () => setShadow(window.scrollY > 10)

        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    const page: Page | undefined = PagesConstant.resolvePage(location.pathname);

    return (
        <div className={`header-wrapper ${shadow ? 'shadow' : ''}`}>
            <div className="header">
                <div className="header-title">
                    {
                        page && <PageBreadcrumb title={page.title} breadCrumb={page.breadcrumb}/>
                    }
                </div>
                <div className="page-menu-wrapper">
                    <HeaderMenu />
                    <UserActionMenu/>
                </div>
            </div>
        </div>
    );
};

export default Header;
