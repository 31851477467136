import axios from "axios";
import {User} from "../models/user";
import TokenStorageService from "./TokenStorageService";

const API_URL = 'auth/profile';

export function get(): Promise<User> {
    if (!TokenStorageService.getToken()) {
        const user: User = {id: '', username: 'user', name: 'User'};
        return Promise.resolve(user);
    }
    return axios.get<User>(`${API_URL}`)
        .then(response => response.data);
}

const userProfileService = {
    get
};

export default userProfileService;