import FileCustom from "_libs/formio/components/FileCutom/FileCustom";
import SelectBoxesCustom from "_libs/formio/components/SelectBoxCustom/SelectBoxCustom";
import SelectCustom from "_libs/formio/components/SelectCustom/SelectCustom";
import stringUtils from "app/utils/string.utils";
import { Formio } from "formiojs-llq";
import _ from "lodash";
import builderComponent from "_libs/formio/templates/builderComponent";
import builder from "_libs/formio/templates/builder";

const formio: any = Formio; 
const customBaseEditForm = () => {
    const baseEditForm = formio.Components.baseEditForm([
        {
            key: 'display',
            components: [
                {
                    key: 'key',
                    weight: 3,
                    type: 'textfield',
                    label: 'Key of component'
                },
            ]
        },
        {
            key: 'api',
            ignore: true
        },
    ])
    const unify = formio.Components.EditFormUtils.unifyComponents
    formio.Components.baseEditForm = (...extend: any) => {
        let components = _.cloneDeep(baseEditForm.components).concat(extend.map((items: any) => {
            return {
                type: 'tabs',
                key: 'tabs',
                components: _.cloneDeep(items)
            };
        }))
        return {
            components: _.unionWith(components, unify).concat({
                type: 'hidden',
                key: 'type'
            })
        };
    }
}

// Add custom component
const addCustomComponent = () => {
    Formio.use({
        components: {
            selectboxescustom: SelectBoxesCustom,
            selectcustom: SelectCustom,
            filecustom: FileCustom,
        }
    });
}

// Override function updateComponentKey
const customUpdateComponentKey = () => {
    const webform = formio.Builders.getBuilder('webform')
    const descriptor: PropertyDescriptor = Object.getOwnPropertyDescriptor(webform.prototype, "updateComponentKey") || {};
    
    if (descriptor) {
        descriptor.value = function (data: any): string {
            let input: string = data.title || data.label || data.placeholder || data.type;
            input = stringUtils.vietnameseToLatin(input)
            return _.camelCase(input).replace(/^[0-9]*/, '');
        }
    }
    Object.defineProperty(webform.prototype, "updateComponentKey", descriptor);
}

const customTemplate = () => {
    const template = formio.Templates.current;
    template.builderComponent = builderComponent;
    template.builder = builder;
}
export default {
    customBaseEditForm,
    customUpdateComponentKey,
    addCustomComponent,
    customTemplate
}