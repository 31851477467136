import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import useUserTasks from "app/hooks/user-task/useUserTasks";
import { UserTask } from "app/models/user-task";
import { ReactElement, useEffect, useState } from "react";

interface Author {
  id: string;
  name: string;
  descripyion: string;
  avatarUrl: ReactElement;
}

interface Quote {
  id: string;
  content: string;
  author: Author;
}

interface QuoteMap {
  [authorName: string]: Quote[];
}

interface UserTaskMap {
  [authorName: string]: UserTask[];
}

const  newTask : Author = {
    id: "1",
    name: "To Do",
    descripyion: 'List To Do',
    avatarUrl: <Avatar size="small" icon={<UserOutlined />} />,
};

const  processingTask : Author  = {
    id: "2",
    name: "Inprogress",
    descripyion: 'List Inprogress',
    avatarUrl: <Avatar size="small" icon={<UserOutlined />} />,
};

const  doneTask : Author  = {
    id: "3",
    name: "Done",
    descripyion: 'List Done',
    avatarUrl: <Avatar size="small" icon={<UserOutlined />} />,
};

// const  reopenTask : Author  = {
//   id: "4",
//   name: "ReOpen Tasks",
//   descripyion: 'List ReOpen Tasks',
//   avatarUrl: <Avatar size="small" icon={<AppstoreOutlined />} />,
// };

export const authors: Author[] = [newTask, processingTask, doneTask];

export const quotes : Quote[] = [
    {
      id: "1",
      content: "Sometimes life is scary and dark",
      author: processingTask
    },
    {
      id: "2",
      content:
        "Sucking at something is the first step towards being sorta good at something.",
      author: newTask
    },
    {
      id: "3",
      content: "You got to focus on what's real, man",
      author: newTask
    },
    {
      id: "4",
      content: "Is that where creativity comes from? From sad biz?",
      author: doneTask
    },
    {
      id: "5",
      content: "Homies help homies. Always",
      author: doneTask
    },
    {
      id: "6",
      content: "Responsibility demands sacrifice",
      author: newTask
    },
    {
      id: "7",
      content: "That's it! The answer was so simple, I was too smart to see it!",
      author: processingTask
    },
    {
      id: "8",
      content:
        "People make mistakes. It's all a part of growing up and you never really stop growing",
      author: doneTask
    },
    {
      id: "9",
      content: "Don't you always call sweatpants 'give up on life pants,' Jake?",
      author: doneTask
    },
    {
      id: "10",
      content: "I should not have drunk that much tea!",
      author: processingTask
    },
    {
      id: "11",
      content: "Please! I need the real you!",
      author: processingTask
    },
    {
      id: "12",
      content: "Haven't slept for a solid 83 hours, but, yeah, I'm good.",
      author: newTask
    }
];

let idCount = quotes.length + 1;

// export const getQuotes = (count: number): UserTask[] => 
//   Array.from({ length: count }, (_, k) => k).map(() => {
//     const [page, setPage] = useState(0);
//     const {data: userTasks, refetch: reload} = useUserTasks({page, completed: false, size: 20});
//     const random = userTasks ? userTasks.content[Math.floor(Math.random() * userTasks.content.length)] : [];
    
//     const custom: UserTask = random ?{
//       ...random
//     } : null;

//     return custom;
//   });

const useGetUserTasks = () => {
  const [page, setPage] = useState(0);
  const { data: userTasks, refetch: reload } = useUserTasks({ page, completed: false, size: 20 });

  useEffect(() => {
  }, [userTasks]);

  return { userTasks, reload };
};

const useGetUserTasksUnassigned = () => {
  const [page, setPage] = useState(0);
  const {data: userTasksUnassigned, refetch: reloadTask} = useUserTasks({page, unassigned: true,  size: 20});

  useEffect(() => {
  }, [userTasksUnassigned]);

  return { userTasksUnassigned, reloadTask };
};

const useGetUserTasksComplete = () => {
  const [page, setPage] = useState(0);
  const {data: userTasksComplete, refetch: reloadTaskComplete} = useUserTasks({page, completed: true, size: 20});

  useEffect(() => {
  }, [userTasksComplete]);

  return { userTasksComplete, reloadTaskComplete };
};

export const useGetRandomUserTasks = (count: number) => {
  const { userTasks, reload } = useGetUserTasks();
  // console.log("UserTask: ",userTasks);
  
  if (!userTasks) {
    return [];
  }

  return Array.from({ length: count }, (_, k) => k).map(() => {
    const random = userTasks.content[Math.floor(Math.random() * userTasks.content.length)];

    if (!random) {
       return {
        id: undefined,
        username: undefined,
        status: undefined
      }; 
    }
    
    const custom: UserTask = {
      ...random,
    };
    
    // console.log("Custome: ", custom);
    return custom;
  });
};
  
export const getAuthors = (count: number): Author[] =>
  Array.from({ length: count }, (_, k) => k).map(() => {
    const random = authors[Math.floor(Math.random() * authors.length)];

    const custom: Author = {
      ...random,
      id: `author-${idCount++}`,
    };

    return custom;
});

const getByAuthor = (author: Author, items: Quote[]): Quote[] =>
  items.filter((quote) => quote.author === author);

export const authorQuoteMap: QuoteMap = authors.reduce(
  (previous, author) => ({
    ...previous,
    [author.name]: getByAuthor(author, quotes),
  }),
  {}
);

export const generateQuoteMap = (quoteCount: number): UserTaskMap =>
  authors.reduce(
    (previous, author) => ({
      ...previous,
      [author.name]: useGetRandomUserTasks(quoteCount / authors.length),
    }),
    {}
  );

  export const UserTasksProvider  = () : UserTaskMap=> {
    const { userTasks, reload } = useGetUserTasks();
    const { userTasksUnassigned, reloadTask } = useGetUserTasksUnassigned();
    const { userTasksComplete, reloadTaskComplete } = useGetUserTasksComplete();
    if (!userTasks) {
      return {}; 
    }
    if (!userTasksUnassigned) {
      return {}; 
    }
    if (!userTasksComplete) {
      return {}; 
    }

    const userTaskMap = Object.fromEntries(
      authors.map((author) => {
        if(author.name === 'To Do'){
          return [author.name, userTasksUnassigned.content]
        }if(author.name === 'Inprogress'){
          return [author.name,  userTasks.content];
        }else {
          return [author.name, userTasksComplete.content]
        }
      })
    );
    return userTaskMap;
  };

