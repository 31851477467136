import React, { ReactElement } from 'react';
import { Droppable, Draggable, DroppableProvided, DroppableStateSnapshot, DraggableProvided, DraggableStateSnapshot, DraggableRubric } from 'react-beautiful-dnd';
import QuoteItem from './quoteItem';
import './styles/style.css';
import { UserTask } from 'app/models/user-task';

interface InnerListProps {
  quotes: UserTask[];
  title?: React.ReactNode;
  dropProvided: DroppableProvided;
}

const InnerQuoteList: React.FC<{ quotes: UserTask[] }> = React.memo(function InnerQuoteList(props) {
    return (
      <>
        {props.quotes.map((quote, index) => (
          <Draggable key={quote.id} draggableId={quote.code} index={index}>
            {(dragProvided) => (
              <QuoteItem
                quote={quote}
                provided={dragProvided}
                style={undefined}
                index={0}
              />
            )}
          </Draggable>
        ))}
      </>
    );
  });

const InnerList: React.FC<InnerListProps> = function InnerList(props) {
  const { quotes, dropProvided } = props;
  const title = props.title ? <div className='title'>{props.title}</div> : null;

  return (
    <div >
      {title}
      <div className='dropzone' ref={dropProvided.innerRef}>
        <InnerQuoteList quotes={quotes} />
        {dropProvided.placeholder}
      </div>
    </div>
  );
};

interface Author {
    id: string;
    name: string;
    descripyion: string;
    avatarUrl: ReactElement;
  }
  
  interface Quote {
    id: string;
    content: string;
    author: Author;
  }
  
interface QuoteListProps {
  ignoreContainerClipping?: boolean;
  internalScroll?: boolean;
  scrollContainerStyle?: React.CSSProperties;
  isDropDisabled?: boolean;
  isCombineEnabled?: boolean;
  listId?: string;
  listType?: string;
  style?: React.CSSProperties;
  quotes: UserTask[];
  title?: React.ReactNode;
  useClone?: boolean;
}

const QuoteList: React.FC<QuoteListProps> = function QuoteList(props) {
  const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listId = 'LIST',
    listType,
    style,
    quotes,
    title,
    useClone,
  } = props;

  return (
    <Droppable
      droppableId={listId}
      type={listType}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      isCombineEnabled={isCombineEnabled}
      renderClone={
        useClone
          ? (
              provided: DraggableProvided,
              snapshot: DraggableStateSnapshot,
              descriptor: DraggableRubric
            ) => (
            <QuoteItem
              quote={quotes[descriptor.source.index]}
              provided={provided}
            //   isClone
            //   isGroupedOver={false}
              style={undefined}
              index={0}
            />
          )
          : undefined
      }
    >
      {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
        <div className='Wrapper'
          style={style}>
          {internalScroll ? (
            <div className='scroll-container' style={scrollContainerStyle}>
              <InnerList quotes={quotes} title={title} dropProvided={dropProvided} />
            </div>
          ) : (
            <InnerList quotes={quotes} title={title} dropProvided={dropProvided} />
          )}
        </div>
      )}
    </Droppable>
  );
};

export default QuoteList;