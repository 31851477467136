import {TOKEN_KEY, PERMISSION_KEY} from "../constants/global.constant";

const storage = localStorage;

export function saveToken(token: string) {
    storage.setItem(TOKEN_KEY, token);
}

export function savePermission(permissions: string[]) {
    storage.setItem(PERMISSION_KEY, btoa(permissions.join('@@@')));
}

export function removeToken() {
    storage.removeItem(TOKEN_KEY);
    storage.removeItem(PERMISSION_KEY);
}

export function getToken() {
    return storage.getItem(TOKEN_KEY);
}

export function getPermission() {
    const permission = storage.getItem(PERMISSION_KEY);
    if(!permission) {
        return [];
    }
    return atob(permission).split('@@@');
}

export function getTokenHeader() {
    return 'Bearer ' + storage.getItem(TOKEN_KEY);
}

const tokenStorageService = {
    saveToken,
    removeToken,
    getToken,
    getPermission,
    getTokenHeader
};

export default tokenStorageService;