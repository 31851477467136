import React, {FC, useState} from "react";
import {Button, message} from "antd";
import PageToolbar from "../../../app/components/common/PageToolbar";
import CreateGroupModal from "./CreateGroupModal";
import useCreateGroup from "../../../app/hooks/group/useCreateGroup";

type Props = {
    total: number;
    reloadGroups: () => void
}

const GroupToolbar: FC<Props> = ({total, reloadGroups}) => {

    const [visible, setVisible] = useState(false);
    const {mutate: createGroup} = useCreateGroup();

    const onCreate = (values: any) => {
        createGroup(values, {
            onSuccess: () => {
                message.success("Thêm mới nhóm người dùng thành công!").then();
                reloadGroups();
            },
            onError: () => {
                message.error("Đã có lỗi xảy ra vui lòng thử lại!").then();
            }
        });
        setVisible(false);
    };

    return (
        <>
            <PageToolbar
                title={
                    <div className="user-page-title">
                        <h4>{total} nhóm người dùng</h4>
                    </div>
                }
                action={
                    <div className="user-page-action">
                        <Button type="primary" shape="round" onClick={() => setVisible(true)}>Thêm mới</Button>
                    </div>
                }
            />
            <CreateGroupModal
                visible={visible}
                onCancel={() => setVisible(false)}
                onCreate={onCreate}
            />
        </>
    )
}

export default GroupToolbar;