import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {FormDefinition} from "../models/form-definition";

export interface FormBuilderState {
  currentForm?: FormDefinition,
  editable?: boolean,
}

const initialState: FormBuilderState = {
};

export const formBuilderSlice = createSlice({
  name: 'formBuilder',
  initialState,
  reducers: {
    setCurrentForm: (state, action: PayloadAction<FormDefinition | undefined>) => {
      state.currentForm = action.payload;
    },
    setEditable: (state, action: PayloadAction<boolean>) => {
      state.editable = action.payload;
    },
    resetState: () => initialState
  },
});

export const { setCurrentForm, setEditable, resetState } = formBuilderSlice.actions;

export const selectCurrentForm = (state: RootState) => state.formBuilder.currentForm;
export const selectEditable = (state: RootState) => state.formBuilder.editable;

const { reducer } = formBuilderSlice;
export default reducer;
