import React, {FC} from "react";
import {Input, Modal} from "antd";
import {useAppDispatch, useAppSelector} from "../../hooks/redux-hooks";
import {hideSpotlightSearch, selectSpotlightSearchVisible} from "../../slices/global-action";
import commonMessages from "../../../translations/messages/common";
import {useIntl} from "react-intl";

type Props = {}

const SpotlightSearchBox: FC<Props> = ({}) => {

    const spotlightSearchVisible = useAppSelector(selectSpotlightSearchVisible);
    const intl = useIntl();

    const dispatch = useAppDispatch();

    const onClose = () => {
        dispatch(hideSpotlightSearch());
    };

    return (
        <Modal
            visible={spotlightSearchVisible}
            onCancel={onClose}
            maskClosable={true}
            closable={false}
            footer={false}
            width={900}
        >
            <Input placeholder={intl.formatMessage({...commonMessages['spotlightSearch.placeholder']})} size="large"/>
        </Modal>
    );
}

export default SpotlightSearchBox;