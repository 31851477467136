import { ReactElement } from "react";
import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import '../styles/style.css';
import QuoteList from "../quoteList";
import { UserTask } from "app/models/user-task";

interface Author {
    id: string;
    name: string;
    descripyion: string;
    avatarUrl: ReactElement;
  }
  
  interface Quote {
    id: string;
    content: string;
    author: Author;
  }

interface ColumnProps {
    key: string;
    index: number;
    title: string;
    quotes:  UserTask[] ;
    isScrollable: boolean;
    isCombineEnabled: boolean;
    useClone: boolean;
  }

const Column: React.FC<ColumnProps> = (props) => {
    const { title, quotes, index } = props;
    
    return (
      <Draggable draggableId={title} index={index}>
        {(provided: DraggableProvided) => (
          <div className="container-column" ref={provided.innerRef} {...provided.draggableProps}>
            <div className="header-board" >
              <div className="title"
                {...provided.dragHandleProps}
                aria-label={`${title} quote list`}
              >
                {title}
              </div>
            </div>
            <QuoteList
              listId={title}
              listType="QUOTE"
              quotes={quotes}
              internalScroll={props.isScrollable}
              isCombineEnabled={props.isCombineEnabled}
              useClone={props.useClone}
            />
          </div>
        )}
      </Draggable>
    );
  };
  
  export default Column;