import React from "react";
import './styles/index.css';
import {Outlet} from "react-router-dom";

export function FormViewerLayout() {

    const currentYear = new Date().getFullYear();

    return (
        <>
            <div className="form-viewer-layout">
                <div className="form-viewer-container">
                    <Outlet/>
                </div>

                <div className="form-viewer-footer">
                    <span className="footer-copyright">{currentYear} ©</span>
                    <a className="footer-logo-link" href="#" target="_blank">Iworkflow.llq.vn</a>
                </div>
            </div>
        </>
    );
}