import axios from 'axios';
import PageResponse from "../models/common/page-response";
import QueryUtils from "../utils/query.utils";
import {CreateFolderCommand, Folder, FolderSearchRequest, UpdateFolderCommand} from "../models/drive";
import {IdResponse} from "../models/common/id.response";

const API_URL = 'spaces';
const DETAIL_API_URL = 'folders';

export function create(request: CreateFolderCommand): Promise<IdResponse<number>> {
    return axios.post<IdResponse<number>>(`spaces/${request.spaceId}/folders`, request)
        .then(response => response.data);
}

export function update(request: UpdateFolderCommand): Promise<IdResponse<number>> {
    return axios.post<IdResponse<number>>(`${API_URL}/${request.spaceId}/folders/${request.id}`, request)
        .then(response => response.data);
}

export function search(request?: FolderSearchRequest): Promise<PageResponse<Folder>> {
    const queryStr =  QueryUtils.toQuery(request);
    return axios.get<PageResponse<Folder>>(`${API_URL}/${request?.spaceId}/folders?${queryStr}`)
        .then(response => response.data);
}

export function getFolderChain(id?: number): Promise<Folder[]> {
    return axios.get<Folder[]>(`${DETAIL_API_URL}/${id}/chain`)
        .then(response => response.data);
}

export function findById(id: number): Promise<Folder> {
    return axios.get<Folder>(`${DETAIL_API_URL}/${id}`)
        .then(response => response.data);
}

export function findByCode(code?: string): Promise<Folder> {
    return axios.get<Folder>(`${DETAIL_API_URL}/codes/${code}`)
        .then(response => response.data);
}

const folderService = {
    create,
    update,
    search,
    findById,
    findByCode,
    getFolderChain
};

export default folderService;