const toQuery = (values: any): string => {
  if (!values) {
    return '';
  }
  let params = new URLSearchParams();
  for(let key in values){
    const value = values[key];
    if (value !== null && value !== undefined) {
      params.set(key, value)
    }
  }
  return params.toString();
}

export default {
  toQuery
}