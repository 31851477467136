import {defineMessages} from 'react-intl';

export const scope = 'app.form';

export default defineMessages({
  'scope.PUBLIC': {
    id: `${scope}.scope.PUBLIC`,
    defaultMessage:
        'Public',
  },
  'scope.INTERNAL': {
    id: `${scope}.scope.INTERNAL`,
    defaultMessage:
        'Internal',
  },

  'scope.RESTRICTED': {
    id: `${scope}.scope.RESTRICTED`,
    defaultMessage:
        'Restricted',
  }
});
