import React from 'react';
import './styles/index.css';

export function ReportPage() {

  return (
      <div className="page-content-wrapper">
          <div className="page-content box">
            Coming soon...
          </div>
      </div>
  );
}