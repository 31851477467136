import axios from 'axios';
import PageResponse from "../models/common/page-response";
import QueryUtils from "../utils/query.utils";
import {Resource, ResourceType, SearchResourceRequest} from "../models/drive";

const API_URL = 'resources';

export function search(request?: SearchResourceRequest): Promise<PageResponse<Resource>> {
    const queryStr =  QueryUtils.toQuery(request);
    return axios.get<PageResponse<Resource>>(`${API_URL}?${queryStr}`)
        .then(response => response.data);
}

export function findByCode(resourceCode?: string, resourceType?: ResourceType): Promise<Resource> {
    const queryStr =  QueryUtils.toQuery({resourceCode: resourceCode, resourceType: resourceType});
    return axios.get<Resource>(`${API_URL}/detail?${queryStr}`)
        .then(response => response.data);
}

const resourceService = {
    search,
    findByCode
};

export default resourceService;