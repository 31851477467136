import React from 'react';
import EzCard from "../../app/components/common/EzCard";
import './styles/index.css';
import useUserTasks from "../../app/hooks/user-task/useUserTasks";
import emptyTask from '../../assets/images/girl-ticked.png';
import emptyNotification from '../../assets/images/happy-girl.svg';
import UserTaskItem from "../../app/components/common/UserTaskItem";
import useNotifications from "../../app/hooks/notification/useNotifications";
import NotificationItem from "./components/NotificationItem";
import { UserTasksProvider, generateQuoteMap } from 'pages/single-task/components/kanban/initialData';
import Board from 'pages/single-task/components/kanban/board/Board';

export function DashboardPage() {
    const {data: todayTasks} = useUserTasks({page: 0, size: 5, completed: false, deadlineToday: true});
    const {data: overdueTasks} = useUserTasks({page: 0, size: 5, completed: false, overdue: true});
    const {data: notifications} = useNotifications({page: 0, size: 10});

    const hasOverdueTasks = (overdueTasks?.totalElements || 0) > 0;
    const hasTodayTasks = (todayTasks?.totalElements || 0) > 0;
    const hasNotifications = (notifications?.totalElements || 0) > 0;
    const data = {
        medium: UserTasksProvider(),
        // large: generateQuoteMap(50)
      };
    //   console.log("Data quote map: ", data.medium);
    return (
        <div className="page-content-wrapper">
            <div className="page-content box">
                {
                    hasOverdueTasks &&
                    <EzCard title={<h3>Công việc quá hạn</h3>} className="mb-3">
                        <div className="dashboard__overdue">
                            {
                                overdueTasks?.content.map((item, index) =>
                                    <UserTaskItem userTask={item} key={index} nearDueHighlight overDueHighlight/>
                                )
                            }
                        </div>
                    </EzCard>
                }

                <EzCard title={<h3>Đến hạn hôm nay</h3>}>
                    <div className="dashboard__deadline-today">
                        {
                            !hasTodayTasks &&
                            <div className="flex flex-column align-items-center justify-content-center gap-5">
                                <img src={emptyTask}/>
                                <p className="text-gray-700 no-margin fw-light text-align-center">
                                    Không có công việc nào <br/> đến hạn trong hôm nay.
                                </p>
                            </div>
                        }
                        {
                            todayTasks?.content.map((item, index) =>
                                <UserTaskItem userTask={item} key={index} nearDueHighlight overDueHighlight/>
                            )
                        }
                    </div>
                </EzCard>

                <EzCard title={<h3>Thông báo mới nhất</h3>} className="mt-3">
                    <div className="dashboard__notification">
                        {
                            !hasNotifications &&
                            <div className="flex flex-column align-items-center justify-content-center gap-5">
                                <img src={emptyNotification}/>
                                <p className="text-gray-700 no-margin fw-light text-align-center">
                                    Không có thông báo mới nào.
                                </p>
                            </div>
                        }
                        <div className="flex flex-column gap-3">
                            {
                                notifications?.content.map((item, index) =>
                                    <NotificationItem key={index} notification={item}/>
                                )
                            }
                        </div>
                    </div>
                </EzCard>

                <EzCard title={<h3>Kanban</h3>} className="mt-3">
                    <Board initial= {data.medium} withScrollableColumns useClone={true} />
                </EzCard>
            </div>
        </div>
    );
}