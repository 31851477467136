import React, {FC} from "react";
import I18n from "./I18n";
import {Tooltip} from "antd";
import {UserTaskType} from "../../models/user-task";

const icons = (value: UserTaskType) => {
    switch (value) {
        case 'TASK':
            return <i className="fi fi-rr-layers text-gray-600"></i>;
        case 'APPROVAL':
            return <i className="fi fi-rr-check text-gray-600"></i>;
    }
}

type Props = {
    value: UserTaskType;
    tooltipVisible?: boolean;
}

const EzUserTaskType: FC<Props> = ({value, tooltipVisible = false}) => {

    return (
        <Tooltip title={tooltipVisible && <I18n type="user-task" prefix="type" messageKey={value}/>}>
            {icons(value)}
        </Tooltip>
    )
}

export default EzUserTaskType;
