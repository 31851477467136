import { Components } from 'formiojs-llq';
import editForm from './SelectCustom.form'
import { getToken } from 'app/services/TokenStorageService';
import EventEmitter2 from 'eventemitter2';
const SelectComponent = (Components as any).components.select
export default class SelectCustom extends (SelectComponent as any) {
    static schema(...extend: any[]) {
        return SelectComponent.schema({
            type: 'selectcustom',
            label: 'Select Custom',
            key: 'selectCustom',
            inline: false,
            data: {
                headers: [],
            },
            disableLimit: true
        }, ...extend);
    }

    static get builderInfo() {
        return {
            title: 'Select - LLQ',
            group: 'basic',
            icon: 'th-list',
            weight: 60,
            documentation: '/userguide/form-building/form-components#select-box',
            schema: SelectCustom.schema()
        };
    }

    init() {
        if (this.component.authenticate === true) {
            this.component.data.headers = [
                { key: 'Authorization', value: ('Bearer ' + getToken()) }
            ]
        }
        super.init()
    }

    updateItems(searchInput: any, forceUpdate: any) {
        if (this.component.dataSrc === 'tables') {
            let { url } = this.component.data;
            let method;
            let body;
            if (!this.component.data.method) {
                method = 'GET';
            }
            else {
                method = this.component.data.method;
                if (method.toUpperCase() === 'POST') {
                    body = this.component.data.body;
                }
                else {
                    body = null;
                }
            }
            const options = this.component.authenticate ? {} : { noToken: true };

            let query: string[] = [];
            if (this.component.precondition) {
                this.component.preconditions.forEach((item: any) => {
                    let expression: string[] = [];
                    item.componentData.forEach((data: any) => {
                        console.log(this);
                        let value = this.getValueByType(data.value)
                        value && expression.push(value);
                    })
                    expression.length && query.push(item.column + '=:' + expression.join());
                })
            }

            let newUrl = '';
            if (query.length) {
                newUrl = url + "&" + (query.join('&'));
            } else {
                newUrl = url;
            }

            this.loadItems(newUrl, searchInput, this.requestHeaders, options, method, body);
        }
        super.updateItems(searchInput, forceUpdate);
    }

    render() {
        return super.render();
    }

    attach(element: any) {
        // console.log('data: ')
        // console.log(this.component.data)
        // let eventEmitter = new EventEmitter2();
        let precondition = this.component.precondition;
        let preconditions = this.component.preconditions;
        this.onAny((event: any, ...args: any) => {
            if (event === 'formio.componentChange') {
                console.log('Vua co component update');
                let componentChanged = args[0].component.key;
                let found = false; // Biến flag

                preconditions && preconditions.forEach((item: any) => {
                    if (found) {
                        return;
                    }

                    item.componentData.forEach((data: any) => {
                        if (data.value.key === componentChanged) {
                            this.updateItems(null, true);
                            found = true;
                            return;
                        }
                    });
                });
            }
        });
        // this.on('formio.componentChange', (abc: any) => {
        //     console.log('Vua co component update')
        //   });

        return super.attach(element)
    }

    getValueByType(component: any) {
        if (component.type === 'selectboxes' || component.type === 'selectboxescustom') {
            let data = this._data[component.key];
            let result: string[] = [];
            for (let key in data) {
                if (data[key] === true) {
                    result.push(key);
                }
            }
            return result.join();
        }
        return this._data[component.key]
    }

    // constructor(component: any, options:Options, data: any) {
    //     super(component, options, data);
    // }

    public static editForm = editForm;
}