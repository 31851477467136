import React, {FC} from "react";
import DateUtils from "../../utils/date.utils";
import EzUserTaskType from "./EzUserTaskType";
import {Link} from "react-router-dom";
import TimeFromNow from "../format/TimeFromNow";
import EzPriority from "./EzPriority";
import {UserTask} from "../../models/user-task";
import './styles/user-task-item.css';

type Props = {
    userTask: UserTask;
    overDueHighlight?: boolean;
    nearDueHighlight?: boolean;
}

const UserTaskItem: FC<Props> = ({userTask, overDueHighlight, nearDueHighlight}) => {

    return (
        <Link to={`/tasks/${userTask.code}`}>
            <div
                className={
                    'user-task-item' +
                    `${overDueHighlight && DateUtils.isPast(userTask.deadline) ? ' overdue' : ''}` +
                    `${nearDueHighlight && DateUtils.isNearFuture(60, userTask.deadline) ? ' near-due' : ''}` +
                    `${DateUtils.isAroundPresent(0.2, userTask.arrivedDate || userTask.createdDate) ? ' new' : ''}`
                }
            >
                <div className="user-task-type flex align-items-center">
                    <EzUserTaskType value={userTask.type}/>
                </div>
                <div className="user-task-title">
                    <h3 className="text-dark no-margin fs-15px fw-simibold text-nowrap text-overflow-ellipsis">
                        {userTask.title}
                    </h3>
                    <p className="text-gray-600 fs-13px no-margin fw-light text-nowrap text-overflow-ellipsis">
                        {userTask.description || 'Không có mô tả'}
                    </p>
                </div>
                <div className="user-task-workflow flex align-items-center">
                    <h4 className="no-margin fs-11px fw-bold text-uppercase text-nowrap text-overflow-ellipsis">
                        {userTask.workflowInstance?.title}
                    </h4>
                    <span className="fs-11px fw-bold text-nowrap text-overflow-ellipsis">
                        {userTask.workflowVersion?.versionName}
                    </span>
                </div>
                <div className="user-task-meta flex align-items-center">
                <span className="user-task-created-date fs-12px text-gray-700 fw-bold">
                    <TimeFromNow value={userTask.arrivedDate || userTask.createdDate}/>
                </span>
                    <EzPriority value={userTask.priority} tooltipVisible={true} iconOnly/>
                </div>
            </div>
        </Link>
    )
}

export default UserTaskItem;