import React, {useState} from 'react';
import '../styles/index.css';
import EzCard from "../../../app/components/common/EzCard";
import {ColumnsType} from "antd/es/table";
import {AddRoleMemberCommand, RemoveRoleMemberCommand, User} from "../../../app/models/user";
import TextAvatar from "../../../app/components/common/TextAvatar";
import {Button, Empty, message, Modal, Table, Tooltip} from "antd";
import ActionButton from "../../../app/components/common/ActionButton";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import useRole from "../../../app/hooks/role/useRole";
import {useParams} from "react-router-dom";
import useRoleMembers from "../../../app/hooks/role/useRoleMembers";
import RoleMemberToolbar from "../components/RoleMemberToolbar";
import AddRoleMemberModal from "../components/AddRoleMemberModal";
import useAddRoleMember from "../../../app/hooks/role/useAddRoleMember";
import ErrorUtils from "../../../app/utils/error.utils";
import useRemoveRoleMember from "../../../app/hooks/role/useRemoveRoleMember";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import UpdateRoleModal from "../components/UpdateRoleModal";
import useUpdateRole from "../../../app/hooks/role/useUpdateRole";

const {confirm} = Modal;

export function RoleDetailPage() {

    const params: any = useParams();
    const [page, setPage] = useState(0);
    const {data: role, refetch: reloadRole} = useRole(+params.roleId);
    const {data: members, refetch: reloadMembers} = useRoleMembers({roleId: +params.roleId, size: 10, page})
    const {mutateAsync: addMember} = useAddRoleMember()
    const {mutate: removeMember} = useRemoveRoleMember()
    const {mutate: updateRole} = useUpdateRole()
    const [addRoleMemberModalVisible, setAddRoleMemberModalVisible] = useState(false);
    const [updateModalVisible, setUpdateModalVisible] = useState(false);

    const onMenuClick = (event: { key: string, data?: User }) => {
        if (!event.data) {
            return;
        }

        const user = event.data;

        confirm({
            title: 'Xác nhận',
            icon: <ExclamationCircleOutlined/>,
            content: "Bạn chắc chắn muốn xóa người dùng khỏi chức vụ này?",
            onOk() {
                onRemoveMember(user);
            }
        });
    }

    const onRemoveMember = (user: User) => {
        const request: RemoveRoleMemberCommand = {
            roleId: +params.roleId,
            userId: user.id
        }
        removeMember(request, {
            onSuccess: () => {
                message.success("Xóa người dùng khỏi chức vụ thành công!");
                reloadMembers().then();
            },
            onError: ErrorUtils.showCommonError
        });
    }

    const columns: ColumnsType<User> = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            align: "center",
            render: (value, record, index) => (
                <>{index + 1}</>
            )
        },
        {
            title: 'Người dùng',
            dataIndex: 'user',
            key: 'user',
            render: (value, record) => (
                <div className="flex align-items-center gap-2">
                    <TextAvatar name={record.name}/>
                    <div className="flex flex-column">
                        <h2 className="fs-14px text-dark no-margin">{record.name}</h2>
                        <span className="fs-14px text-gray-400">{record.username}</span>
                    </div>
                </div>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 160
        },
        {
            title: '##',
            dataIndex: 'actions',
            key: 'actions',
            align: "center",
            width: 30,
            render: (value, record) => (
                <ActionButton menuItems={menuItems} onClick={onMenuClick} data={record}/>
            )
        },
    ];

    const menuItems: ItemType[] = [
        {key: 'remove', label: 'Xóa khỏi chức vụ này', danger: true},
    ];

    const onAddMember = (user: User): Promise<any> => {
        const request: AddRoleMemberCommand = {
            roleId: +params.roleId,
            userId: user.id
        }
        return addMember(request)
            .then(() => {
                message.success("Thêm người dùng thành công");
                reloadMembers().then();
            }).catch((e: any) => {
                if (ErrorUtils.is(e, '100', '405')) {
                    message.error("Người dùng đã có chức vụ này. Vui lòng thử lại!", 5).then();
                    return;
                }
                message.error("Đã có lỗi xảy ra. Vui lòng thử lại!")
            });
    }

    const onUpdate = (values: any) => {
        setUpdateModalVisible(false);
        values.id = +params.roleId;
        updateRole(values, {
            onSuccess: () => {
                message.success("Cập nhật thành công!");
                reloadRole().then();
            },
            onError: ErrorUtils.showCommonError
        })
    }

    const onPageChange = (page: number) => {
      setPage(page - 1);
    }

    const isEmpty = (members?.totalElements || 0) === 0;

    return (
        <>
            <div className="page-content-wrapper">
                <RoleMemberToolbar/>
                <div className="page-content box role-detail-page-content">
                    <EzCard
                        title={
                            <div className="role-detail-title">
                                <div className="flex gap-2 align-items-center">
                                    <h2 className="no-margin">{role?.name}</h2>
                                    {
                                        role?.authorizationEnable &&
                                        <Tooltip title="Có thể ủy quyền">
                                            <i className="fi fi-rr-shuffle fs-18px"></i>
                                        </Tooltip>
                                    }
                                </div>
                                <div className="mt-1">
                                    <span className="fs-14px text-gray-500">{role?.description || 'Không có mô tả'}</span>
                                </div>
                            </div>
                        }
                        toolbar={<Button shape="circle" className="btn-icon" onClick={() => setUpdateModalVisible(true)}><i className="fi fi-rr-edit"></i></Button>}
                        footer={false}
                    ></EzCard>

                    <EzCard
                        title={<h3>Danh sách người dùng giữ chức vụ này</h3>}
                        footer={isEmpty}
                        toolbar={<Button type="primary" shape="round"
                                         onClick={() => setAddRoleMemberModalVisible(true)}>Thêm mới</Button>}
                        className="mt-3"
                    >
                        {
                            isEmpty && <Empty/>
                        }
                        {
                            !isEmpty &&
                            <Table
                                bordered
                                className="data-table"
                                columns={columns}
                                dataSource={members?.content || []}
                                rowKey={'id'}
                                pagination={{
                                    total: members?.totalElements,
                                    onChange: onPageChange
                                }}
                            />
                        }
                    </EzCard>
                </div>
            </div>
            <AddRoleMemberModal
                visible={addRoleMemberModalVisible}
                onCancel={() => setAddRoleMemberModalVisible(false)}
                onAddMember={onAddMember}
                roleId={+params.roleId}

            />
            {
                role &&
                <UpdateRoleModal
                    visible={updateModalVisible}
                    onUpdate={onUpdate}
                    onCancel={() => setUpdateModalVisible(false)}
                    role={role}
                />
            }
        </>
    );
}