import {CreateRoleCommand, Role, RoleSearchRequest, UpdateRoleCommand} from "../models/user";
import QueryUtils from "../utils/query.utils";
import axios from "axios";
import PageResponse from "../models/common/page-response";
import {IdResponse} from "../models/common/id.response";

const API_URL = 'roles';

export function search(request?: RoleSearchRequest): Promise<PageResponse<Role>> {
    const queryStr =  QueryUtils.toQuery(request);
    return axios.get<PageResponse<Role>>(`${API_URL}?${queryStr}`)
        .then(response => response.data);
}

export function create(request: CreateRoleCommand): Promise<IdResponse<number>> {
    return axios.post<IdResponse<number>>(`${API_URL}`, request)
        .then(response => response.data);
}

export function update(request: UpdateRoleCommand): Promise<IdResponse<number>> {
    return axios.put<IdResponse<number>>(`${API_URL}/${request.id}`, request)
        .then(response => response.data);
}

export function findById(id: number): Promise<Role> {
    return axios.get<Role>(`${API_URL}/${id}`)
        .then(response => response.data);
}

const roleService = {
    search,
    create,
    update,
    findById
};

export default roleService;