import { useEffect } from 'react'
import { useAppDispatch } from '../../../app/hooks/redux-hooks'
import { logout } from '../../../app/slices/auth'
import Keycloak from 'keycloak-js'

export function LogoutPage() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(logout()).then(() => {
      //   window.location.href = `${process.env.REACT_APP_AUTH_BASE_URL}/logout?client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}`
      const keycloak = new Keycloak({
        url: process.env.REACT_APP_AUTH_BASE_URL,
        realm: process.env.REACT_APP_AUTH_REALM || '',
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID || ''
      })
      keycloak.init({ responseMode: 'query' }).then(() => {
        keycloak.logout({ redirectUri: process.env.REACT_APP_DOMAIN })
      })
    })
  }, [dispatch])

  return <>Logging out...</>
}
