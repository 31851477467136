import {defineMessages} from 'react-intl';

export const scope = 'app.file-storage';

export default defineMessages({
  'scope.PRIVATE': {
    id: `${scope}.scope.PRIVATE`,
    defaultMessage:
        'Private',
  },
  'scope.PUBLIC': {
    id: `${scope}.scope.PUBLIC`,
    defaultMessage:
        'Public internet',
  },

  'status.ACTIVE': {
    id: `${scope}.status.ACTIVE`,
    defaultMessage:
        'Active',
  },
  'status.DELETED': {
    id: `${scope}.status.DELETED`,
    defaultMessage:
        'Deleted',
  },
  'status.IN_PROGRESS': {
    id: `${scope}.status.IN_PROGRESS`,
    defaultMessage:
        'In progress',
  },
  'status.UPLOAD_FAILED': {
    id: `${scope}.status.UPLOAD_FAILED`,
    defaultMessage:
        'Upload failed',
  },
  'status.RETENTION_EXPIRED': {
    id: `${scope}.status.RETENTION_EXPIRED`,
    defaultMessage:
        'Retention expired',
  },
  'status.UPLOADING': {
    id: `${scope}.status.UPLOADING`,
    defaultMessage:
        'Uploading',
  },

  'source.FORM': {
    id: `${scope}.source.FORM`,
    defaultMessage:
        'Form',
  },
  'source.WORKFLOW': {
    id: `${scope}.source.WORKFLOW`,
    defaultMessage:
        'Workflow',
  },
  'source.WORKFLOW_VERSION': {
    id: `${scope}.source.WORKFLOW_VERSION`,
    defaultMessage:
        'Workflow version',
  },
  'source.WORKFLOW_INSTANCE': {
    id: `${scope}.source.WORKFLOW_INSTANCE`,
    defaultMessage:
        'Workflow instance',
  },
  'source.DIRECT_UPLOAD': {
    id: `${scope}.source.DIRECT_UPLOAD`,
    defaultMessage:
        'Upload directly',
  },

  'type.FORM_PRINT_TEMPLATE': {
    id: `${scope}.type.FORM_PRINT_TEMPLATE`,
    defaultMessage:
        'Form print template',
  },
  'type.WORKFLOW_DOCUMENT_MERGE_RESULT': {
    id: `${scope}.type.WORKFLOW_DOCUMENT_MERGE_RESULT`,
    defaultMessage:
        'Workflow document merge result',
  },
  'type.WORKFLOW_DOCUMENT_MERGE_TEMPLATE': {
    id: `${scope}.type.WORKFLOW_DOCUMENT_MERGE_TEMPLATE`,
    defaultMessage:
        'Workflow document merge template',
  },
  'type.RANDOM': {
    id: `${scope}.type.RANDOM`,
    defaultMessage:
        'Random',
  },
  'type.FORM_DATA_UPLOAD': {
    id: `${scope}.type.FORM_DATA_UPLOAD`,
    defaultMessage:
        'Form data upload',
  },
  'type.WORKFLOW_INSTANCE_UPLOAD': {
    id: `${scope}.type.WORKFLOW_INSTANCE_UPLOAD`,
    defaultMessage:
        'Workflow instance upload',
  },

  'category.status.DRAFT': {
    id: `${scope}.category.status.DRAFT`,
    defaultMessage:
        'Draft',
  },
  'category.status.ACTIVE': {
    id: `${scope}.category.status.ACTIVE`,
    defaultMessage:
        'Active',
  },
  'category.status.INACTIVE': {
    id: `${scope}.category.status.INACTIVE`,
    defaultMessage:
        'Inactive',
  },

  'retention.status.DRAFT': {
    id: `${scope}.retention.status.DRAFT`,
    defaultMessage:
        'Draft',
  },
  'retention.status.ACTIVE': {
    id: `${scope}.retention.status.ACTIVE`,
    defaultMessage:
        'Active',
  },
  'retention.status.INACTIVE': {
    id: `${scope}.retention.status.INACTIVE`,
    defaultMessage:
        'Inactive',
  },

  'retention.action.NO_ACTION': {
    id: `${scope}.retention.action.NO_ACTION`,
    defaultMessage:
        'Do nothing',
  },
  'retention.action.PERMANENTLY_DELETE': {
    id: `${scope}.retention.action.PERMANENTLY_DELETE`,
    defaultMessage:
        'Permanently delete',
  },
});
