import React, {FC} from 'react';
import './styles/footer.css';

type Props = {}

const Footer: FC<Props> = () => {

    const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-text">
          <span className="footer-copyright">{currentYear} ©</span>
          <a className="footer-logo-link" href="#" target="_blank">iWorkflow.llq.vn</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
