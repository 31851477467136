import {ColumnsType} from "antd/es/table";
import {Link} from "react-router-dom";
import ActionButton from "../../../app/components/common/ActionButton";
import React from "react";
import {Group} from "../../../app/models/group";

const columns = (menuItems: any, onMenuClick: (event: any) => void): ColumnsType<Group> => [
    {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        width: 50,
        align: "center",
        render: (value, record, index) => (
            <>{index + 1}</>
        )
    },
    {
        title: 'Tên nhóm',
        dataIndex: 'name',
        key: 'name',
        render: (value, record) => (
            <Link to={`/groups/${record.id}`}>
                <h4 className="text-underline-hover text-dark fs-16px no-margin">{record.name}</h4>
            </Link>
        )
    },
    {
        title: 'Mô tả',
        dataIndex: 'description',
        key: 'description'
    },
    {
        title: '##',
        dataIndex: 'actions',
        key: 'actions',
        align: "center",
        width: 30,
        render: (value, record) => (
            <ActionButton menuItems={menuItems} onClick={onMenuClick} data={record}/>
        )
    },
];

export default {
    columns
}