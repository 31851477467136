import axios from 'axios'
import { removeToken, saveToken, savePermission } from './TokenStorageService'
import userProfileService from './UserProfileService'

const API_URL = process.env.REACT_APP_API_BASE_URL

export function login(code: string) {
  return axios.get(API_URL + '/auth/token?code=' + code).then(response => {
    if (response.data.accessToken) {
      saveToken(response.data.accessToken)
      return getPermissions().then(() => response.data)
    }
    return response.data
  })
}

export function getPermissions() {
  return userProfileService.get().then(response => {
    if (response.permissions) {
      savePermission(response.permissions)
    }
    return response
  })
}

export function logout() {
  removeToken()
  return new Promise<any>(resolve => setTimeout(() => resolve(true), 0))
}

const authService = {
  login,
  logout
}

export default authService
