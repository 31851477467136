import {defineMessages} from 'react-intl';

export const scope = 'app.account';

export default defineMessages({
  'roles.ACCOUNT_OWNER': {
    id: `${scope}.roles.ACCOUNT_OWNER`,
    defaultMessage:
        'Owner',
  },
  'roles.ACCOUNT_ADMIN': {
    id: `${scope}.roles.ACCOUNT_ADMIN`,
    defaultMessage:
        'Admin',
  },
  'roles.ACCOUNT_MEMBER': {
    id: `${scope}.roles.ACCOUNT_MEMBER`,
    defaultMessage:
        'Member',
  }
});
