import React, {FC, useEffect, useState} from 'react';
import {Button, Input, Modal, Table} from "antd";
import {User} from "../../../app/models/user";
import {ColumnsType} from "antd/es/table";
import TextAvatar from "../../../app/components/common/TextAvatar";
import useUsers from "../../../app/hooks/user/useUsers";
import Search from "antd/es/input/Search";

type Props = {
    visible: boolean;
    onAddMember: (values: any) => Promise<any>;
    onCancel: () => void;
    roleId: number;
}

const AddRoleMemberModal: FC<Props> = ({visible, onAddMember, onCancel, roleId}) => {

    const [query, setQuery] = useState<string>();
    const [page, setPage] = useState(0);
    const {data: users, refetch} = useUsers({size: 10, query, page});

    useEffect(() => {
        if (visible) {
            setQuery(undefined);
            refetch().then();
        }
    }, [visible]);

    const addMember = (user: User) => {
        onAddMember(user)
            .then(() => refetch().then());
    }

    const userColumns: ColumnsType<User> = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            align: "center",
            render: (value, record, index) => (
                <>{index + 1}</>
            )
        },
        {
            title: 'Người dùng',
            dataIndex: 'user',
            key: 'user',
            render: (value, record) => (
                <div className="flex align-items-center gap-2">
                    <TextAvatar name={record.name}/>
                    <div className="flex flex-column">
                        <h2 className="fs-14px text-dark no-margin">{record.name}</h2>
                        <span className="fs-14px text-gray-400">{record.username}</span>
                    </div>
                </div>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 160
        },
        {
            title: '##',
            dataIndex: 'actions',
            key: 'actions',
            align: "center",
            width: 30,
            render: (value, record) => (
                <Button className="btn-icon" type="primary" shape="circle" onClick={() => addMember(record)}>
                    <i className="fi fi-sr-plus"></i>
                </Button>
            )
        },
    ];

    const onSearch = (keyword: string) => {
        setQuery(keyword);
    }

    const onPageChange = (page: number) => {
      setPage(page - 1);
    }

    return (
        <Modal
            visible={visible}
            footer={false}
            title="Thêm người dùng"
            onCancel={onCancel}
            width={900}
            style={{top: '20px'}}
            destroyOnClose
        >
            <Search
                placeholder="Tìm kiếm..."
                onSearch={onSearch}
                enterButton
                allowClear
            />
            <Table
                bordered
                className="data-table mt-2"
                columns={userColumns}
                dataSource={users?.content || []}
                rowKey={'id'}
                pagination={{
                    total: users?.totalElements,
                    onChange: onPageChange
                }}
            />
        </Modal>
    );
}

export default AddRoleMemberModal;