import React from "react";
import { ReactElement } from "react";
import './styles/style.css';
import { UserTask } from "app/models/user-task";
import { Avatar } from "antd";
import {HddOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";


interface QuoteItemProps {
    quote: UserTask;
    provided: any;
    style: any;
    index: number;
  }
  interface Quote {
    id: string;
    content: string;
    author: Author;
  }
  
  interface Author {
    id: string;
    name: string;
    descripyion: string;
    avatarUrl: ReactElement;
  }; 
  
  const QuoteItem: React.FC<QuoteItemProps> = function QuoteItem(props) {
    const { quote,provided, index } = props;
   
        // console.log("Quote Item: ", quote);   

    return (
      <Link to={`/tasks/${quote.code}`}>
        <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        data-testid={quote.id}
        data-index={index}
        aria-label={`${quote.type} quote ${quote.description}`}
      >
        <div>
          <div className="item">
            <div className="avatar">
              <Avatar size="small" icon={<HddOutlined />} />
                {/* {quote.author.id} */}
                {/* <span>{quote.author.avatarUrl} - {quote.author.name}</span> */}
            </div>
            <div className="content">
                <h3 className="text-dark no-margin fs-15px fw-simibold text-nowrap text-overflow-ellipsis">
                  {quote.title}
                </h3>
                <p className="text-gray-600 fs-13px no-margin fw-light text-nowrap text-overflow-ellipsis">
                  {quote.description || 'Không có mô tả'}
                </p> 
            </div>
            <div className="version">
              {quote.workflowInstance.title} - {quote.workflowVersion?.versionName}
            </div>
           </div>
        </div>
      </div>
      </Link>
      
    );
  };
  
  export default React.memo(QuoteItem);