import React, {FC} from 'react';
import {Form, Input, Modal} from "antd";
import {Group} from "../../../app/models/group";

type Props = {
    visible: boolean;
    onUpdate: (values: any) => void;
    onCancel: () => void;
    group: Group;
}

const UpdateGroupModal: FC<Props> = ({visible, onUpdate, onCancel, group}) => {

    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            title="Cập nhật nhóm người dùng"
            okText="Cập nhật"
            cancelText="Cancel"
            onCancel={onCancel}
            width={880}
            style={{top: '20px'}}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        onUpdate(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{...group}}
            >
                <Form.Item
                    name="name"
                    label="Tên nhóm"
                    rules={[{ required: true, message: 'Trường này là bắt buộc' }]}
                >
                    <Input placeholder="Tên chức vụ"/>
                </Form.Item>
                <Form.Item name="description" label="Mô tả">
                    <Input.TextArea placeholder="Mô tả"/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default UpdateGroupModal;