import {
    CreateGroupCommand,
    Group,
    GroupSearchRequest,
    UpdateGroupCommand,
    UpdateGroupPermissionCommand
} from "../models/group";
import QueryUtils from "../utils/query.utils";
import axios from "axios";
import PageResponse from "../models/common/page-response";
import {IdResponse} from "../models/common/id.response";

const API_URL = 'groups';

export function search(request?: GroupSearchRequest): Promise<PageResponse<Group>> {
    const queryStr =  QueryUtils.toQuery(request);
    return axios.get<PageResponse<Group>>(`${API_URL}?${queryStr}`)
        .then(response => response.data);
}

export function create(request: CreateGroupCommand): Promise<IdResponse<string>> {
    return axios.post<IdResponse<string>>(`${API_URL}`, request)
        .then(response => response.data);
}

export function update(request: UpdateGroupCommand): Promise<any> {
    return axios.put<any>(`${API_URL}/${request.id}`, request)
        .then(response => response.data);
}

export function findById(id: number): Promise<Group> {
    return axios.get<Group>(`${API_URL}/${id}`)
        .then(response => response.data);
}

export function updatePermission(request: UpdateGroupPermissionCommand): Promise<any> {
    return axios.put<any>(`${API_URL}/${request.id}/permissions`, request)
        .then(response => response.data);
}

const groupService = {
    search,
    create,
    update,
    findById,
    updatePermission
};

export default groupService;