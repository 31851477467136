import React, {FC} from "react";
import {Notification} from "../../../app/models/notification";
import TextAvatar from "../../../app/components/common/TextAvatar";
import {DateFormatter} from "../../../app/components/format";
import TimeFromNow from "../../../app/components/format/TimeFromNow";
import {Link} from "react-router-dom";

type Props = {
    notification: Notification
}

const NotificationItem: FC<Props> = ({notification}) => {

    if (notification.type === 'COMMENT' && notification.action === 'MENTION') {
        return (
            <div className="db-noti-item flex gap-3">
                <div className="db-noti-item__trigger">
                    <TextAvatar name={notification.triggerBy?.name} size="small"/>
                </div>
                <div className="db-noti-item__content">
                    <div className="db-noti-item__title flex gap-2 align-items-center">
                        <span className="fw-bold text-dark fs-14px">{notification.triggerBy?.name}</span>
                        <span className="fs-13px text-gray-500">đã nhắc đến bạn trong một bình luận</span>
                        <span className="fs-13px text-gray-600">
                            <TimeFromNow value={notification.createdDate}/>
                        </span>
                    </div>
                    <div className="db-noti-item__detail mt-1">
                        <h3 className="fs-16px text-gray-700">
                            <Link to={`/tasks/${notification.commentMention?.userTask?.code || ''}`}>
                                {notification.commentMention?.title}
                            </Link>
                        </h3>
                        <span className="fs-14px text-gray-500">
                            {notification.commentMention?.content}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    if (notification.type === 'USER_TASK' && notification.action === 'ASSIGN') {
        return (
            <>
                <div className="db-noti-item flex gap-3">
                    <div className="db-noti-item__trigger">
                        <TextAvatar name={notification.triggerBy?.name} size="small"/>
                    </div>
                    <div className="db-noti-item__content">
                        <div className="db-noti-item__title flex gap-2 align-items-center">
                            <span className="fw-bold text-dark fs-14px">{notification.triggerBy?.name}</span>
                            <span className="fs-13px text-gray-500">đã chuyển đến bạn một công việc</span>
                            <span className="fs-13px text-gray-600">
                            <TimeFromNow value={notification.createdDate}/>
                        </span>
                        </div>
                        <div className="db-noti-item__detail mt-1">
                            <h3 className="fs-16px text-gray-700">
                                {notification.userTaskAssignment?.title}
                            </h3>
                            <span className="fs-14px text-gray-500">
                            {notification.userTaskAssignment?.description}
                        </span>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <></>
    )
}

export default NotificationItem;