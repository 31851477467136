import _ from "lodash";

const addPermission = (jsonSchema: any, permission: any) => {
    if (typeof jsonSchema === 'object') {
        if (jsonSchema instanceof Array) {
            for (let i = 0; i < jsonSchema.length; i++) {
                addPermission(jsonSchema[i], permission);
            }
        } else {
            if (jsonSchema && jsonSchema['key']) {
                let key = jsonSchema['key'];
                if (permission[key] === 'hidden') {
                    jsonSchema['hidden'] = true;
                    jsonSchema['disabled'] = false;
                } else if (permission[key] === 'readonly') {
                    jsonSchema['disabled'] = true;
                    jsonSchema['hidden'] = false;
                } else {
                    jsonSchema['disabled'] = false;
                    jsonSchema['hidden'] = false;
                }
            }
            for (let key in jsonSchema) {
                addPermission(jsonSchema[key], permission);
            }
        }
    }
}

const getContextComponentsWithType = (context: any, excludeNested: boolean, excludedTypes: string[] = []) => {
    const values: any = [];

  context.utils.eachComponent(context.instance.options.editForm.components, (component: any, path: string) => {
    const addToContextComponents = excludeNested ? !component.tree : true;
    if (component.key !== context.data.key && addToContextComponents && !_.includes(excludedTypes, component.type)) {
      values.push({
        label: `${component.label || component.key} (${path})`,
        value: {key: component.key, type: component.type},
      });
    }
  });

  return values;
}

const FormioUtils = {
    addPermission,
    getContextComponentsWithType
}
export default FormioUtils;