import PageResponse from "../models/common/page-response";
import QueryUtils from "../utils/query.utils";
import axios from "axios";
import {ReassignCommand, UpdatePriorityCommand, UserTask, UserTaskSearchRequest} from "../models/user-task";

const API_URL = 'user-tasks';

export function search(request?: UserTaskSearchRequest): Promise<PageResponse<UserTask>> {
    const queryStr =  QueryUtils.toQuery(request);
    return axios.get<PageResponse<UserTask>>(`${API_URL}?${queryStr}`)
        .then(response => response.data);
}

export function findByCode<T>(code: string): Promise<T> {
    return axios.get<T>(`${API_URL}/${code}`)
        .then(response => response.data);
}

export function getFormWithPermission(nodeId:string, formId: string): Promise<any> {
    return axios.get<any>(`${API_URL}/${nodeId}/form/${formId}`)
        .then(response => JSON.parse(response.data.formio?.content?response.data.formio?.content:''));
}

export function reassign(request: ReassignCommand): Promise<any> {
    return axios.post<any>(`${API_URL}/${request.userTaskId}/reassign`, request)
        .then(response => response.data);
}

export function updatePriority(request: UpdatePriorityCommand): Promise<any> {
    return axios.put<any>(`${API_URL}/${request.userTaskId}/priority`, request)
        .then(response => response.data);
}

const userTaskService = {
    search,
    findByCode,
    getFormWithPermission,
    reassign,
    updatePriority
};

export default userTaskService;