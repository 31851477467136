import React, {FC} from "react";
import './styles/ez-card.css';

type Props = {
    title?: any;
    toolbar?: any;
    footer?: any | false;
    className?: string;
    children?: React.ReactNode;
}

const EzCard: FC<Props> = ({title, toolbar, className = '', footer, children}) => {

    return (
        <div className={`ez-card ${className}`}>
            {
                (title || toolbar) &&
                <div className="card-header">
                    <div className="card-title">
                        {title}
                    </div>
                    {
                        toolbar &&
                        <div className="card-toolbar">
                            {toolbar}
                        </div>
                    }
                </div>
            }
            <div className="card-body">
                {children}
            </div>
            {
                footer !== false &&
                <div className="card-footer">
                    {footer}
                </div>
            }
        </div>
    )
}

export default EzCard;