import {LANGUAGE_KEY} from "../constants/global.constant";
import {SupportedLanguage} from "../models/supported-language";

const storage = localStorage;

export function saveLanguage(key: SupportedLanguage) {
    storage.setItem(LANGUAGE_KEY, key);
}

export function removeLanguage() {
    storage.removeItem(LANGUAGE_KEY);
}

export function getLanguage(defaultLang: SupportedLanguage): SupportedLanguage {
    const lang: any = storage.getItem(LANGUAGE_KEY);
    return lang || defaultLang;
}

const languageStorageService = {
    saveLanguage,
    removeLanguage,
    getLanguage
};

export default languageStorageService;