import React, {FC} from 'react';
import {Badge, Dropdown, Menu} from 'antd';
import './styles/page-menu.css';
import {useAppDispatch, useAppSelector} from "../../hooks/redux-hooks";
import {changeLanguage, selectLanguage, showSidebarCalendar, showSpotlightSearch} from "../../slices/global-action";
import NotificationBox from "./NotificationBox";
import {ItemType} from "antd/lib/menu/hooks/useItems";

type Props = {}

const HeaderMenu: FC<Props> = ({}) => {
    const dispatch = useAppDispatch();
    const language = useAppSelector(selectLanguage);

    const onShowSidebarMenu = () => {
        dispatch(showSidebarCalendar());
    };

    const onShowSpotlightSearch = () => {
        dispatch(showSpotlightSearch());
    };

    const onChangeLanguage = (selectedMenu: any) => {
        dispatch(changeLanguage(selectedMenu.key));
    };

    const languageMenuItems: ItemType[] = [
        {key: 'en', label: 'English'},
        {key: 'vi', label: 'Việt Nam'},
    ]

    const languageMenu = () => (
        <Menu items={languageMenuItems} onClick={onChangeLanguage} selectedKeys={[language]}/>
    );

    return (
        <div className="page-menu">
            <div className="menu-item">
                <i className="fi fi-sr-interrogation"></i>
            </div>
            <div className="menu-item" onClick={onShowSpotlightSearch}>
                <i className="fi fi-sr-search"></i>
            </div>
            <Dropdown overlay={<NotificationBox/>} trigger={['click']} placement="bottom" arrow>
                <div className="menu-item">
                    <i className="fi fi-sr-comment-alt"></i>
                </div>
            </Dropdown>
            <div className="menu-item" onClick={onShowSidebarMenu}>
                <i className="fi fi-sr-calendar"></i>
            </div>
            {/*<Dropdown overlay={languageMenu} trigger={['click']} placement="bottom" arrow>*/}
            {/*    <Badge count={language} offset={[-5, 5]}>*/}
            {/*        <div className="menu-item">*/}
            {/*            <i className="fi fi-sr-globe"></i>*/}
            {/*        </div>*/}
            {/*    </Badge>*/}
            {/*</Dropdown>*/}
        </div>
    );
};

export default HeaderMenu;
