import {defineMessages} from 'react-intl';

export const scope = 'app.common';

export default defineMessages({
  'buttons.detail': {
    id: `${scope}.buttons.detail`,
    defaultMessage: 'Detail',
  },
  'buttons.archive': {
    id: `${scope}.buttons.archive`,
    defaultMessage: 'Archive',
  },
  'buttons.edit': {
    id: `${scope}.buttons.edit`,
    defaultMessage: 'Edit',
  },

  'userMenu.profile': {
    id: `${scope}.userMenu.profile`,
    defaultMessage: 'Profile',
  },
  'userMenu.changePassword': {
    id: `${scope}.userMenu.changePassword`,
    defaultMessage: 'Change password',
  },
  'userMenu.authorization': {
    id: `${scope}.userMenu.authorization`,
    defaultMessage: 'Authorization',
  },
  'userMenu.resetPassword': {
    id: `${scope}.userMenu.resetPassword`,
    defaultMessage: 'Reset password',
  },
  'userMenu.logout': {
    id: `${scope}.userMenu.logout`,
    defaultMessage: 'Logout',
  },

  'spotlightSearch.placeholder': {
    id: `${scope}.spotlightSearch.placeholder`,
    defaultMessage: 'Search for forms, folders...',
  },
  'usageStatus.label': {
    id: `${scope}.usageStatus.label`,
    defaultMessage: 'Usage status',
  },

  'priority.LOW': {
    id: `${scope}.priority.LOW`,
    defaultMessage: 'Low',
  },
  'priority.MEDIUM': {
    id: `${scope}.priority.MEDIUM`,
    defaultMessage: 'Medium',
  },
  'priority.HIGH': {
    id: `${scope}.priority.HIGH`,
    defaultMessage: 'High',
  },
  'priority.VERY_HIGH': {
    id: `${scope}.priority.VERY_HIGH`,
    defaultMessage: 'Very high',
  },
});
