import {defineMessages} from 'react-intl';

export const scope = 'app.user';

export default defineMessages({
  'status.ACTIVE': {
    id: `${scope}.status.ACTIVE`,
    defaultMessage:
        'Active',
  },
  'status.LOCKED': {
    id: `${scope}.status.LOCKED`,
    defaultMessage:
        'Locked',
  }
});
