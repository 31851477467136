import React, {FC, useState} from 'react';
import {Dropdown, Menu} from 'antd';
import ShortTextFormatter from "../../components/format/ShortTextFormatter";
import {Link, useNavigate} from "react-router-dom";
import './styles/user-action.css';
import ChangePasswordModal from "./ChangePasswordModal";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import commonMessages from "../../../translations/messages/common";
import {FormattedMessage} from "react-intl";
import useUserProfile from "../../hooks/auth/useUserProfile";

type Props = {}

const UserActionMenu: FC<Props> = () => {
    const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false);
    const navigate = useNavigate();
    const {data: userProfile} = useUserProfile();

    const showChangePasswordModal = () => {
        setChangePasswordModalVisible(true);
    }

    const closeChangePasswordModal = () => {
        setChangePasswordModalVisible(false);
    }

    const resetPassword = () => {
        navigate("/request-reset-password");
    }

    const goToAuthorization = () => {
        navigate("/authorization");
    }

    const onLogout = () => {
        navigate("/logout");
    }

    const menuItems: ItemType[] = [
        {
            key: 1,
            label: <Link to={`/profile`}>
                    <span className="header-user-menu-content">
                        <i className="fi fi-rr-mode-portrait"/> <FormattedMessage {...commonMessages['userMenu.profile']} />
                    </span>
            </Link>
        },
        {
            key: 2,
            label: <span className="header-user-menu-content">
                    <i className="fi fi-rr-fingerprint"/> <FormattedMessage {...commonMessages['userMenu.changePassword']} />
                </span>,
            onClick: showChangePasswordModal
        },
        {
            key: 3,
            label: <span className="header-user-menu-content">
                    <i className="fi fi-rr-refresh"/> <FormattedMessage {...commonMessages['userMenu.resetPassword']} />
                </span>,
            onClick: resetPassword
        },
        {
            type: 'divider'
        },
        {
            key: 'authorize',
            label: <span className="header-user-menu-content">
                    <i className="fi fi-sr-badge"></i> <FormattedMessage {...commonMessages['userMenu.authorization']} />
                </span>,
            onClick: goToAuthorization
        },
        {
            type: 'divider'
        },
        {
            key: 4,
            label: <span className="header-user-menu-content">
                    <i className="fi fi-rr-sign-out"/> <FormattedMessage {...commonMessages['userMenu.logout']} />
                </span>,
            onClick: onLogout,
            danger: true
        },
    ];

    const menu = () => (
        <Menu className="header-user-menu" items={menuItems}/>
    );

    return (
        <div className="page-header-actions">
            <Dropdown overlay={menu} trigger={['click']} arrow placement="bottom">
                <div className="page-header-user">
                    <span className="header-user-name">{userProfile?.name}</span>
                    <span className="header-user-icon">
                        <ShortTextFormatter value={userProfile?.name || '__'} scale={2} lastWord/>
                    </span>
                </div>
            </Dropdown>
            <ChangePasswordModal visible={changePasswordModalVisible} onClose={closeChangePasswordModal}/>
        </div>
    );
};

export default UserActionMenu;
