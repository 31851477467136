import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import globalActionReducer from './slices/global-action';
import authReducer from './slices/auth';
import formBuilderReducer from './slices/form-builder';

export const store = configureStore({
  reducer: {
    globalAction: globalActionReducer,
    auth: authReducer,
    formBuilder: formBuilderReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
