import {AddRoleMemberCommand, RemoveRoleMemberCommand, RoleMemberSearchRequest, User} from "../models/user";
import QueryUtils from "../utils/query.utils";
import axios from "axios";
import PageResponse from "../models/common/page-response";

const API_URL = 'roles';

export function search(request: RoleMemberSearchRequest): Promise<PageResponse<User>> {
    const queryStr =  QueryUtils.toQuery(request);
    return axios.get<PageResponse<User>>(`${API_URL}/${request.roleId}/members?${queryStr}`)
        .then(response => response.data);
}

export function add(request: AddRoleMemberCommand): Promise<any> {
    return axios.post<any>(`${API_URL}/${request.roleId}/members`, request)
        .then(response => response.data);
}

export function remove(request: RemoveRoleMemberCommand): Promise<any> {
    return axios.delete<any>(`${API_URL}/${request.roleId}/members/${request.userId}`)
        .then(response => response.data);
}

const roleMemberService = {
    search,
    add,
    remove
};

export default roleMemberService;