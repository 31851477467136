import {RcFile} from "antd/es/upload";

const toFormData = (request: any, fileKeys: string[] = ['file']): FormData => {
  const formData = new FormData();
  for (const key of Object.keys(request)) {
    const val = request[key];
    if (val) {
      if (fileKeys.includes(key)) {
        formData.append(key, val as RcFile);
      } else {
        formData.append(key, val);
      }
    }
  }
  return formData;
}

export default {
  toFormData
}