import React, {FC, MouseEventHandler} from "react";
import ColorConstant from "../../constants/color.constant";
import StringUtils from "../../utils/string.utils";
import ShortTextFormatter from "../format/ShortTextFormatter";
import {Avatar, Badge} from "antd";
import {AvatarSize} from "antd/lib/avatar/SizeContext";
import './styles/text-avatar.css'

type Props = {
    name?: any;
    empty?: string;
    displayName?: any;
    className?: string;
    size?: AvatarSize;
    showName?: boolean;
    color?: string;
    showAll?: boolean;
    badgeCount?: any;
    onClick?: MouseEventHandler
}

const TextAvatar: FC<Props> = ({name, displayName = name, size= 'large', showName = false, color, showAll, badgeCount, onClick, ...rest}) => {

    if (!name) {
        return <></>
    }

    if (!color) {
        color = ColorConstant.random(StringUtils.lastWordOf(name));
    }

    return (
        <div className={`text-avatar ${onClick ? 'clickable' : ''}`} onClick={onClick}>
            <Badge count={badgeCount} offset={[-5, 5]}>
                <Avatar
                    {...rest}
                    size={size}
                    gap={4}
                    style={{ backgroundColor: color}}
                >
                    {!showAll && <span><ShortTextFormatter value={name} lastWord/></span>}
                    {showAll && <span>{name}</span>}
                </Avatar>
            </Badge>
            {
                showName && <span>{displayName}</span>
            }
        </div>
    )
}

export default TextAvatar;