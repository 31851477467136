import React from 'react';
import {IntlProvider} from "react-intl";
import {Route, Routes} from "react-router-dom";
import {PrimaryLayout} from "./app/layouts/PrimaryLayout";
import {RequireAuth} from "./app/components/route/RequireAuth";
import {LogoutPage} from "./pages/auth/pages/LogoutPage";
import {DashboardPage} from "./pages/dashboard/DashboardPage";
import RolePages from "./pages/role";
import {ReportPage} from "./pages/reports/ReportPage";
import {FormViewerLayout} from "./app/layouts/FormViewerLayout";
import moment from "moment";
import 'moment/locale/vi'
import GroupPages from "./pages/group";

const messages = {
    en: require('./translations/en.json'),
    vi: require('./translations/vi.json')
};

const Login = React.lazy(() => import("./pages/auth"));
const TaskPages = React.lazy(() => import("./pages/user-task"));
const DrivePages = React.lazy(() => import("./pages/drive"));
const FormPages = React.lazy(() => import("./pages/form-definition"));
const FormViewPages = React.lazy(() => import("./pages/form-view"));
const UploadedFilePages = React.lazy(() => import("./pages/file-storage"));
const FileCategoryPages = React.lazy(() => import("./pages/file-category"));
const FileRetentionPages = React.lazy(() => import("./pages/file-retention"));
const WorkflowPages = React.lazy(() => import("./pages/workflow-definition"));
const UserPages = React.lazy(() => import("./pages/user"));
const SettingPages = React.lazy(() => import("./pages/settings"));
const AuthorizationPages = React.lazy(() => import("./pages/authorization"));
const SingleTaskConfigPages = React.lazy(() => import("./pages/single-task-config"));
const SingleTaskPages = React.lazy(() => import("./pages/single-task"));
const WorkflowStarterPages = React.lazy(() => import("./pages/workflow-starter"));
const DatabasePages = React.lazy(() => import("./pages/database"));
const DatabaseAppPages = React.lazy(() => import("./pages/database-app"));
const WorkflowSetupPages = React.lazy(() => import('pages/workflow-setup'));
const CatalogUnitPages = React.lazy(() => import('pages/catalog-unit'));
const CatalogDepartmentPages = React.lazy(() => import('pages/catalog-department'));
const CatalogPositionPages = React.lazy(() => import('pages/catalog-position'));

// disable all react-beautiful-dnd development warnings
// @ts-ignore
window['__react-beautiful-dnd-disable-dev-warnings'] = true;

function App() {
    const language = "vi";//useAppSelector(selectLanguage);
    moment.locale(language);

    return (
        <IntlProvider locale={language} messages={messages[language]} onError={() => {}} fallbackOnEmptyString={true}>
            <Routes>
                <Route>
                    <Route path="login/*" element={<React.Suspense fallback={<></>}><Login/></React.Suspense>}/>
                    <Route path="/logout" element={<LogoutPage/>}/>
                </Route>

                <Route element={<FormViewerLayout />}>
                    <Route path="/workflows/starter/*" element={
                        <React.Suspense fallback={<></>}>
                            <WorkflowStarterPages/>
                        </React.Suspense>
                    }/>
                </Route>

                <Route>
                    <Route path="/workflows/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><WorkflowPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                </Route>
                <Route>
                    <Route path="/single-task-config/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><SingleTaskConfigPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                </Route>
                <Route>
                    <Route path="/single-tasks/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><SingleTaskPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                </Route>
                <Route element={<FormViewerLayout />}>
                    <Route path="/forms/v/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><FormViewPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                </Route>
                <Route>
                    <Route path="/forms/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><FormPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                </Route>
                <Route>
                    <Route path="/workflow-setup/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><WorkflowSetupPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                </Route>

                <Route element={<PrimaryLayout/>}>
                    <Route path="/" element={<RequireAuth><DashboardPage/></RequireAuth>}/>
                    <Route path="/reports" element={<RequireAuth><ReportPage/></RequireAuth>}/>
                    <Route path="/tasks/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><TaskPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                    <Route path="/drive/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><DrivePages/></RequireAuth>
                        </React.Suspense>
                    }/>
                    <Route path="/files/categories/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><FileCategoryPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                    <Route path="/files/retention-policies/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><FileRetentionPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                    <Route path="/files/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><UploadedFilePages/></RequireAuth>
                        </React.Suspense>
                    }/>
                    <Route path="/users/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><UserPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                    <Route path="/roles/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><RolePages/></RequireAuth>
                        </React.Suspense>
                    }/>
                    <Route path="/groups/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><GroupPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                    <Route path="/settings/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><SettingPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                    <Route path="/authorization/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><AuthorizationPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                    <Route path="/databases/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><DatabasePages/></RequireAuth>
                        </React.Suspense>
                    }/>
                     <Route path="/apps/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><DatabaseAppPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                     <Route path="/catalog-units/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><CatalogUnitPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                     <Route path="/catalog-departments/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><CatalogDepartmentPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                     <Route path="/catalog-positions/*" element={
                        <React.Suspense fallback={<></>}>
                            <RequireAuth><CatalogPositionPages/></RequireAuth>
                        </React.Suspense>
                    }/>
                </Route>
            </Routes>
        </IntlProvider>
    );
}

export default App;
