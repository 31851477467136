import { UpdateUserCommand, UpdateUserPermissionCommand, User, UserSearchRequest, CreateUserCommand } from '../models/user'
import PageResponse from '../models/common/page-response'
import QueryUtils from '../utils/query.utils'
import axios from 'axios'

const API_URL = 'users'

export function search(request?: UserSearchRequest): Promise<PageResponse<User>> {
  const queryStr = QueryUtils.toQuery(request)
  return axios.get<PageResponse<User>>(`${API_URL}?${queryStr}`).then(response => response.data)
}

export function create(request: CreateUserCommand): Promise<any> {
  return axios.post(`${API_URL}`, request).then(response => response.data)
}

export function update(request: UpdateUserCommand): Promise<any> {
  return axios.put(`${API_URL}/${request.id}`, request).then(response => response.data)
}

export function updatePermission(request: UpdateUserPermissionCommand): Promise<any> {
  return axios.put<any>(`${API_URL}/${request.id}/permissions`, request)
      .then(response => response.data);
}

const userService = {
  search,
  create,
  update,
  updatePermission
}

export default userService
