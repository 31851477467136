import {defineMessages} from 'react-intl';

export const scope = 'app.folder';

export default defineMessages({
  'label': {
    id: `${scope}.label`,
    defaultMessage: 'Folders',
  },
  'label.file': {
    id: `${scope}.label.file`,
    defaultMessage: 'Resources',
  },
  'buttons.addNew': {
    id: `${scope}.buttons.addNew`,
    defaultMessage: 'Add new',
  },
  'buttons.addNew.folder': {
    id: `${scope}.buttons.addNew.folder`,
    defaultMessage: 'Add new folder',
  },
  'buttons.addNew.file': {
    id: `${scope}.buttons.addNew.file`,
    defaultMessage: 'Add new file',
  },
});
