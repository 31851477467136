import TokenStorageService from "../services/TokenStorageService";
import JwtUtils from "./jwt.utils";

export const hasPermission = (permission: string): boolean => {
    // const token = TokenStorageService.getToken();
    // if (!token) {
    //     return false;
    // }
    // const jwt = JwtUtils.parseJwt(token);
    // return jwt.permissions?.includes(permission);
    return TokenStorageService.getPermission().includes(permission);
}

export const hasAnyPermission = (permissions: string[]): boolean => {
    // const token = TokenStorageService.getToken();
    // if (!token) {
    //     return false;
    // }
    // const jwt = JwtUtils.parseJwt(token);
    // const jwtPermissions: string[] = jwt.permissions || [];
    // return jwtPermissions.some(item => permissions.includes(item)); 
    return TokenStorageService.getPermission().some(item => permissions.includes(item));
}