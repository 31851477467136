import React, {FC} from "react";
import './styles/notification-box.css';

type Props = {}

const NotificationBox: FC<Props> = ({}) => {

    return (
        <div className="ant-dropdown-menu">
            <div className=" notification-box">

            </div>
        </div>
    );
}

export default NotificationBox;