import {message} from "antd";

const is = (error: any, code: string, subCode: string): boolean => {
  return error.response?.data?.code === code && error.response?.data?.subCode === subCode;

}

const showCommonError = () => {
  message.error("Đã có lỗi xảy ra. Vui lòng thử lại!")
}

const showErrorAndStopLoading = (key: string) => {
  message.error({content: "Đã có lỗi xảy ra. Vui lòng thử lại!", key: key})
}

export default {
  is,
  showCommonError,
  showErrorAndStopLoading
}