import axios from 'axios';
import PageResponse from "../models/common/page-response";
import QueryUtils from "../utils/query.utils";
import {IdResponse} from "../models/common/id.response";
import {CreateSpaceCommand, Space, SearchSpaceRequest, UpdateSpaceCommand} from "../models/drive";

const API_URL = 'spaces';

export function search(request?: SearchSpaceRequest): Promise<PageResponse<Space>> {
    const queryStr =  QueryUtils.toQuery(request);
    return axios.get<PageResponse<Space>>(`${API_URL}?${queryStr}`)
        .then(response => response.data);
}

export function findById(id: number): Promise<Space> {
    return axios.get<Space>(`${API_URL}/${id}`)
        .then(response => response.data);
}

export function create(request: CreateSpaceCommand): Promise<IdResponse<number>> {
    return axios.post<IdResponse<number>>(`${API_URL}`, request)
        .then(response => response.data);
}

export function update(request: UpdateSpaceCommand): Promise<any> {
    return axios.put(`${API_URL}/${request.id}`, request)
        .then(response => response.data);
}

const spaceService = {
    search,
    findById,
    create,
    update
};

export default spaceService;