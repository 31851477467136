import React, {useState} from 'react';
import '../styles/index.css';
import EzCard from "../../../app/components/common/EzCard";
import {ColumnsType} from "antd/es/table";
import {AddGroupMemberCommand, RemoveGroupMemberCommand} from "../../../app/models/group";
import TextAvatar from "../../../app/components/common/TextAvatar";
import {Button, Empty, message, Modal, Space, Table, Tabs} from "antd";
import ActionButton from "../../../app/components/common/ActionButton";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import useGroup from "../../../app/hooks/group/useGroup";
import {useParams} from "react-router-dom";
import GroupMemberToolbar from "../components/GroupMemberToolbar";
import AddGroupMemberModal from "../components/AddGroupMemberModal";
import useAddGroupMember from "../../../app/hooks/group/useAddGroupMember";
import ErrorUtils from "../../../app/utils/error.utils";
import useRemoveGroupMember from "../../../app/hooks/group/useRemoveGroupMember";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import UpdateGroupModal from "../components/UpdateGroupModal";
import useUpdateGroup from "../../../app/hooks/group/useUpdateGroup";
import useGroupMembers from "../../../app/hooks/group/useGroupMembers";
import {User} from "../../../app/models/user";
import PermissionAssigment from "../components/PermissionAssigment";
import {hasPermission} from "../../../app/utils/auth.utils";

const {confirm} = Modal;

export function GroupDetailPage() {

    const params: any = useParams();
    const [page, setPage] = useState(0);
    const {data: group, refetch: reloadGroup} = useGroup(params.groupId);
    const {data: members, refetch: reloadMembers} = useGroupMembers({groupId: params.groupId, size: 10, page})
    const {mutateAsync: addMember} = useAddGroupMember()
    const {mutate: removeMember} = useRemoveGroupMember()
    const {mutate: updateGroup} = useUpdateGroup()
    const [addGroupMemberModalVisible, setAddGroupMemberModalVisible] = useState(false);
    const [updateModalVisible, setUpdateModalVisible] = useState(false);

    const onMenuClick = (event: { key: string, data?: User }) => {
        if (!event.data) {
            return;
        }

        const user = event.data;

        confirm({
            title: 'Xác nhận',
            icon: <ExclamationCircleOutlined/>,
            content: "Bạn chắc chắn muốn xóa người dùng khỏi nhóm này?",
            onOk() {
                onRemoveMember(user);
            }
        });
    }

    const onRemoveMember = (user: User) => {
        const request: RemoveGroupMemberCommand = {
            groupId: params.groupId,
            userId: user.id
        }
        removeMember(request, {
            onSuccess: () => {
                message.success("Xóa người dùng khỏi nhóm thành công!");
                reloadMembers().then();
            },
            onError: ErrorUtils.showCommonError
        });
    }

    const columns: ColumnsType<User> = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            align: "center",
            render: (value, record, index) => (
                <>{index + 1}</>
            )
        },
        {
            title: 'Người dùng',
            dataIndex: 'user',
            key: 'user',
            render: (value, record) => (
                <div className="flex align-items-center gap-2">
                    <TextAvatar name={record.name}/>
                    <div className="flex flex-column">
                        <h2 className="fs-14px text-dark no-margin">{record.name}</h2>
                        <span className="fs-14px text-gray-400">{record.username}</span>
                    </div>
                </div>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 160
        },
        {
            title: '##',
            dataIndex: 'actions',
            key: 'actions',
            align: "center",
            width: 30,
            render: (value, record) => (
                <ActionButton menuItems={menuItems} onClick={onMenuClick} data={record}/>
            )
        },
    ];

    const menuItems: ItemType[] = [
        {key: 'remove', label: 'Xóa khỏi nhóm này', danger: true},
    ];

    const onAddMember = (user: User): Promise<any> => {
        const request: AddGroupMemberCommand = {
            groupId: params.groupId,
            userId: user.id
        }
        return addMember(request)
            .then(() => {
                message.success("Thêm người dùng thành công");
                reloadMembers().then();
            }).catch((e: any) => {
                if (ErrorUtils.is(e, '100', '405')) {
                    message.error("Người dùng đã có trong nhóm này. Vui lòng thử lại!", 5).then();
                    return;
                }
                message.error("Đã có lỗi xảy ra. Vui lòng thử lại!")
            });
    }

    const onUpdate = (values: any) => {
        setUpdateModalVisible(false);
        values.id = params.groupId;
        updateGroup(values, {
            onSuccess: () => {
                message.success("Cập nhật thành công!");
                reloadGroup().then();
            },
            onError: ErrorUtils.showCommonError
        })
    }

    const onPageChange = (page: number) => {
      setPage(page - 1);
    }

    const isEmpty = (members?.totalElements || 0) === 0;

    return (
        <>
            <div className="page-content-wrapper">
                <GroupMemberToolbar/>
                <div className="page-content box group-detail-page-content">
                    <EzCard
                        title={
                            <div className="group-detail-title">
                                <div className="flex gap-2 align-items-center">
                                    <h2 className="no-margin">{group?.name}</h2>
                                </div>
                                <div className="mt-1">
                                    <span className="fs-14px text-gray-500">{group?.description || 'Không có mô tả'}</span>
                                </div>
                            </div>
                        }
                        toolbar={<Button shape="circle" className="btn-icon" onClick={() => setUpdateModalVisible(true)}><i className="fi fi-rr-edit"></i></Button>}
                        footer={false}
                    ></EzCard>

                    <EzCard
                        footer={isEmpty}
                        toolbar={false}
                        className="mt-3 pt-1"
                    >
                        <Tabs defaultActiveKey="list" size={"large"}>
                            <Tabs.TabPane tab="Danh sách người dùng" key="list">
                                {
                                    hasPermission('write:group') &&
                                    <div className="flex justify-content-space-between align-items-center pt-1 mb-2">
                                        <span></span>
                                        <Button type="primary" shape="round" onClick={() => setAddGroupMemberModalVisible(true)}>Thêm mới</Button>
                                    </div>
                                }
                                {
                                    isEmpty && <Empty/>
                                }
                                {
                                    !isEmpty &&
                                    <Table
                                        bordered
                                        className="data-table"
                                        columns={columns}
                                        dataSource={members?.content || []}
                                        rowKey={'id'}
                                        pagination={{
                                            total: members?.totalElements,
                                            onChange: onPageChange
                                        }}
                                    />
                                }
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Phân quyền" key="permissions">
                                <div className="pb-20px">
                                    {
                                        group &&
                                        <PermissionAssigment group={group} />
                                    }
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    </EzCard>
                </div>
            </div>
            <AddGroupMemberModal
                visible={addGroupMemberModalVisible}
                onCancel={() => setAddGroupMemberModalVisible(false)}
                onAddMember={onAddMember}
                groupId={params.groupId}

            />
            {
                group &&
                <UpdateGroupModal
                    visible={updateModalVisible}
                    onUpdate={onUpdate}
                    onCancel={() => setUpdateModalVisible(false)}
                    group={group}
                />
            }
        </>
    );
}